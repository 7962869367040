import { triggerRefreshWithOnlineCheck } from "../services/appFunctions";
import { handleError } from "../services/commonUsefulFunctions";
import { SECOND_IN_MS } from "../services/globalVariables";
import {
  hasComponentBeenForceRefreshed,
  resetHasComponentBeenForceRefreshed,
  setHasComponentBeenForceRefreshed,
} from "./localData";
// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406

// How to handle crashes
// https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export default async function componentLoader({
  lazyComponent,
  componentName,
  attemptsLeft = 3,
}) {
  const pageHasAlreadyBeenForceRefreshed =
    hasComponentBeenForceRefreshed(componentName);

  const forceRefresh = () => {
    if (pageHasAlreadyBeenForceRefreshed) {
      return;
    }
    // Assuming that the user is not on the latest version of the application.
    // Let's refresh the page immediately.
    setHasComponentBeenForceRefreshed(componentName);
    triggerRefreshWithOnlineCheck();
  };

  try {
    return new Promise((resolve, reject) => {
      lazyComponent()
        .then((value) => {
          resetHasComponentBeenForceRefreshed(componentName);
          resolve(value);
        })
        .catch((error) => {
          // let us retry after 1500 ms
          setTimeout(() => {
            if (attemptsLeft === 1) {
              if (!pageHasAlreadyBeenForceRefreshed) {
                forceRefresh();
              }
              reject(error);
              return;
            }

            componentLoader({
              lazyComponent,
              componentName,
              attemptsLeft: attemptsLeft - 1,
            }).then(resolve, reject);
          }, 0.5 * SECOND_IN_MS);
        });
    });
  } catch (error) {
    handleError(error);
    forceRefresh();
  }
}
