import {
  handleError,
  getMemoryUsageData,
  isTestEnvironment,
  getBrowserType,
} from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import { constructRequestURL } from "../services/api";
import _ from "underscore";
import packageJson from "../../../package.json";
import { differenceInMinutes } from "date-fns";
import { getSignupAttribution } from "../services/queryParamFunctions";
import { getDefaultHeaders } from "../lib/fetchFunctions";
import { getUserToken } from "../lib/userFunctions";
import { isUserMaestroUser } from "../services/maestroFunctions";
import { getMasterAccountFromStore } from "../lib/zustandFunctions";

let lastMemoryTrack;

export function trackOnLoginLoginAttribution({ isOutlook, src }) {
  const attribution = getSignupAttribution();
  if (attribution) {
    trackEvent({
      category: "attribution",
      action: `onClick_login_attribution_${
        isOutlook ? `${src}_outlook` : `${src}_google`
      }`,
      label: attribution,
    });
  }
}

export function trackReferral({ user, action }) {
  trackEvent({
    category: "web_tracking",
    action,
    label: "referral",
    userToken: getUserToken(user),
  });
}

export function trackFeatureUsage({ action, userToken }) {
  if (isTestEnvironment()) {
    return;
  }
  // Track:
  // V,
  // event form -> create/edit
  // slots
  // personal links
  // meet with
  // time travel
  // group vote
  trackEvent({
    category: "web_tracking",
    action,
    label: "feature_tracking",
    userToken,
  });
}

/**
 * @param {Object} options
 * @param {string=} options.category
 * @param {string=} options.action
 * @param {string=} options.event_name
 * @param {string=} options.slots_type
 * @param {string=} options.label
 * @param {string=} options.userToken
 */
export function trackEvent({
  category,
  action,
  event_name,
  slots_type,
  label,
  userToken = "",
}) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const version = packageJson?.version || "no_version";
  const masterAccount = getMasterAccountFromStore();
  let data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    event_action: action,
    category,
    event_name,
    slots_type,
    label,
    timestamp: NOW.toISOString(),
    client: getBrowserType(),
    url: window.location.href,
    version: `version: ${version} || isVimcalEA: ${isUserMaestroUser(masterAccount)}`,
    user_token: userToken,
  };

  if (!lastMemoryTrack || differenceInMinutes(NOW, lastMemoryTrack) > 1) {
    lastMemoryTrack = NOW;
    data = { ...data, ...getMemoryUsageData() };
  }

  const path = "metrics";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      handleError(error);
    });
}

export function trackError({ category, errorMessage, userToken }) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    category,
    errorMessage,
    user_token: userToken,
    timestamp: NOW.toISOString(),
    version: packageJson?.version || "no_version",
    client: getBrowserType(),
  };

  const path = "metrics/e";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      handleError(error);
    });
}

export function trackInstall({ attribution, userToken }) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    attribution,
    user_token: userToken,
    timestamp: NOW.toISOString(),
    client: getBrowserType(),
    version: packageJson?.version || "no_version",
  };

  const path = "metrics/a";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      handleError(error);
    });
}

export function testTracking(currentUser) {
  const userToken = getUserToken(currentUser);
  trackError({
    category: "test",
    errorMessage: "error message",
    userToken,
  });
  trackInstall({
    attribution: "test",
    userToken,
  });
  trackEvent({
    category: "App",
    action: "loaded_app",
    label: "app_loaded",
    userToken,
  });
}

export function addDataLayerTracking(event) {
  if (isTestEnvironment() || !window?.dataLayer?.push) {
    return;
  }

  try {
    window.dataLayer.push(event);
  } catch (error) {
    handleError(error);
  }
}
