import { isElectron, shadeInputColor } from "../services/commonUsefulFunctions";
import {
  DARK_MODE_BACKGROUND_COLOR,
  DARK_MODE_SECONDARY_TEXT_COLOR,
  DARK_MODE_TEXT_COLOR,
  DEFAULT_FONT_COLOR,
  LIGHT_MODE_SECONDARY_TEXT_COLOR,
} from "../services/globalVariables";
import { isTemporaryAIEvent } from "./availabilityFunctions";
import {
  doesEventSpanMultipleDays,
  shouldShowTransparentMergedBackground,
} from "./eventFunctions";
import { isFindTimeEventFormEvent } from "./temporaryEventFunctions";

export function getDefaultFontColor(isDarkMode) {
  return isDarkMode ? DARK_MODE_TEXT_COLOR : DEFAULT_FONT_COLOR;
}

export function getDefaultSecondaryTextColor(isDarkMode) {
  return isDarkMode
    ? DARK_MODE_SECONDARY_TEXT_COLOR
    : LIGHT_MODE_SECONDARY_TEXT_COLOR;
}

export function getSwitchOffColor(isDarkMode) {
  return isDarkMode ? "#67686F" : "#C8CDD4";
}

export function getTentativeBackgroundStyle(color) {
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 10px,
    ${shadeInputColor(color)} 10px,
    ${shadeInputColor(color)} 20px`;
}

export function getTentativeSideBarBackgroundStyle({ color, isDarkMode }) {
  const defaultBackgroundColor = getDefaultBackgroundColor(isDarkMode);
  return `repeating-linear-gradient(
    45deg,
    ${color},
    ${color} 3px,
    ${defaultBackgroundColor} 3px,
    ${defaultBackgroundColor} 6px`;
}

export function getDefaultBackgroundColor(isDarkMode) {
  return isDarkMode ? DARK_MODE_BACKGROUND_COLOR : "white";
}

export function getDefaultMonthlyCalendarOutOfRangeColor(isDarkMode) {
  // "#202027" is taken from inspector because the original color has transparency
  return isDarkMode ? "#202027" : "#F8F9FA";
}

const MODAL_BACKGROUND_TYPE = {
  WITH_OPACITY: "WITH_OPACITY",
  WITHOUT_OPACITY: "WITHOUT_OPACITY",
};

function getDarkModeModalBackgroundColorWithNoOpacity(type) {
  switch (type) {
    case MODAL_BACKGROUND_TYPE.WITH_OPACITY:
      return "rgba(52, 54, 69, 0.5)";
    case MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY:
      return "rgba(52, 54, 69, 1)";
    default:
      return "rgba(52, 54, 69, 1)";
  }
}

export function getDefaultModalBorder(isDarkMode) {
  return isDarkMode ? "1px solid #3C3E4D" : "1px solid #E5E7EB"; // .default-bottom-left-modal-border
}

export function getCalendarGridColor(isDarkMode) {
  return isDarkMode ? "#343645" : "#E5E7EB";
}

export function getModalBackgroundColorWithNoOpacity(isDarkMode) {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(MODAL_BACKGROUND_TYPE.WITHOUT_OPACITY)
    : "rgb(255, 255, 255)";
}

export const getModalBackgroundColorWithExtraOpacity = (isDarkMode) => {
  return isDarkMode
    ? getDarkModeModalBackgroundColorWithNoOpacity(MODAL_BACKGROUND_TYPE.WITH_OPACITY)
    : "rgba(255, 255, 255, 0.4)";
};

export function shouldShowTransparentEventBackgroundInMonthlyView({
  event,
  isInMonthlyView,
}) {
  if (!isInMonthlyView) {
    return false;
  }
  if (shouldShowTransparentMergedBackground(event)) {
    // this is taken care of by `background` property
    return false;
  }
  // we only show background color on the event if it's an all day event or event that spans multiple days
  return (
    !event?.displayAsAllDay &&
    !doesEventSpanMultipleDays(event) &&
    !(isTemporaryAIEvent(event) || isFindTimeEventFormEvent(event))
  );
}
