import React from "react";
import { getFadedColor } from "./commonUsefulFunctions";
import { DEFAULT_PRIMARY_CALENDAR_COLOR } from "./globalVariables";
import CircleWithColor from "../components/circleWithColor";
import { getColorLabels } from "../lib/settingsFunctions";
import {
  getColorLabelLabel,
  getMatchingColorLabel,
} from "../components/settings/colorLabel/colorLabelFunctions";
import produce from "immer";

// same as primaryEventsColors but with -1
export const primaryEventsColors = {
  1: {
    name: "Lavender",
    color: "#7986cb",
    fadedColor: "#C6DAFC",
    nickName: "Purple",
    id: "1",
  },
  2: {
    name: "Sage",
    color: "#33b679",
    fadedColor: "#C2E9D7",
    nickName: "Green",
    id: "2",
  },
  3: {
    name: "Grape",
    color: "#8e24aa",
    fadedColor: "#DDBDE6",
    nickName: "Purple",
    id: "3",
  },
  4: {
    name: "Flamingo",
    color: "#e67c73",
    fadedColor: "#F8D8D5",
    nickName: "Orange, pink",
    id: "4",
  },
  5: {
    name: "Banana",
    color: "#f6c026",
    fadedColor: "#FDECBE",
    nickName: "Yellow",
    id: "5",
  },
  6: {
    name: "Tangerine",
    color: "#f5511d",
    fadedColor: "#FDCBBD",
    nickName: "Red",
    id: "6",
  },
  7: {
    name: "Peacock",
    color: "#039be5",
    fadedColor: "#B3E1F7",
    nickName: "Blue",
    id: "7",
  },
  8: {
    name: "Graphite",
    color: "#616161",
    fadedColor: "#D0D0D0",
    nickName: "Grey",
    id: "8",
  },
  9: {
    name: "Blueberry",
    color: "#3f51b5",
    fadedColor: "#C5CBEA",
    nickName: "Blue, purple",
    id: "9",
  },
  10: {
    name: "Basil",
    color: "#0b8043",
    fadedColor: "#B6D9C7",
    nickName: "Green",
    id: "10",
  },
  11: {
    name: "Tomato",
    color: "#d60000",
    fadedColor: "#F3B3B4",
    nickName: "Red",
    id: "11",
  },
  0: {
    name: "default",
    color: "#039be5",
    fadedColor: "#B3E1F7",
    nickName: "Blue",
    id: "0",
  },
};

const tagColors = {
  ...primaryEventsColors,
  "-1": {
    name: "No Color",
    color: "transparent",
    fadedColor: "transparent",
    nickName: "None",
    id: "-1",
  },
};

const googleColors = {
  tagColors,
  kind: "calendar#colors",
  updated: "2012-02-14T00:00:00.000Z",
  calendar: {
    24: {
      background: "#9e69af",
      foreground: "#1d1d1d",
      fadedColor: "#E1D2E6",
      name: "Amethyst",
    },
    4: {
      background: "#f4511e",
      foreground: "#1d1d1d",
      fadedColor: "#FCCCBB",
      name: "Tangerine",
    },
    7: {
      background: "#009688",
      foreground: "#1d1d1d",
      fadedColor: "#B3E0DB",
      name: "Eucalyptus",
    },
    16: {
      background: "#3f51b5",
      foreground: "#1d1d1d",
      fadedColor: "#C5CBE9",
      name: "Blueberry",
    },
    5: {
      background: "#ef6c00",
      foreground: "#1d1d1d",
      fadedColor: "#FAD3B3",
      name: "Pumpkin",
    },
    17: {
      background: "#7986cb",
      foreground: "#1d1d1d",
      fadedColor: "#E4E7F3",
      name: "Lavender",
    },
    22: {
      background: "#d81b60",
      foreground: "#1d1d1d",
      fadedColor: "#F3BBCE",
      name: "Cherry Blossom",
    },
    2: {
      background: "#e67c73",
      foreground: "#1d1d1d",
      fadedColor: "#F8D8D5",
      name: "Flamingo",
    },
    3: {
      background: "#d50000",
      foreground: "#1d1d1d",
      fadedColor: "#F2B3B2",
      name: "Tomato",
    },
    6: {
      background: "#f09300",
      foreground: "#1d1d1d",
      fadedColor: "#FADFB2",
      name: "Mango",
    },
    8: {
      background: "#0b8043",
      foreground: "#1d1d1d",
      fadedColor: "#B6D9C7",
      name: "Basil",
    },
    9: {
      background: "#7cb342",
      foreground: "#1d1d1d",
      fadedColor: "#D8E8C6",
      name: "Pistachio",
    },
    10: {
      background: "#c0ca33",
      foreground: "#1d1d1d",
      fadedColor: "#EDF0C2",
      name: "Avocado",
    },
    11: {
      background: "#e4c441",
      foreground: "#1d1d1d",
      fadedColor: "#F7EDC6",
      name: "Citron",
    },
    12: {
      background: "#f6bf26",
      foreground: "#1d1d1d",
      fadedColor: "#FCEDBE",
      name: "Banana",
    },
    13: {
      background: "#33b679",
      foreground: "#1d1d1d",
      fadedColor: "#C2E9D7",
      name: "Sage",
    },
    14: {
      background: "#039be5",
      foreground: "#1d1d1d",
      fadedColor: "#B2E1F8",
      name: "Peacock",
    },
    15: {
      background: "#4285f4",
      foreground: "#1d1d1d",
      fadedColor: "#C7D9FC",
      name: "Cobalt",
    },
    18: {
      background: "#b39ddb",
      foreground: "#1d1d1d",
      fadedColor: "#E8E1F4",
      name: "Wisteria",
    },
    19: {
      background: "#616161",
      foreground: "#1d1d1d",
      fadedColor: "#D0D0D0",
      name: "Graphite",
    },
    20: {
      background: "#a79b8e",
      foreground: "#1d1d1d",
      fadedColor: "#E5E1DD",
      name: "Birch",
    },
    21: {
      background: "#ad1457",
      foreground: "#1d1d1d",
      fadedColor: "#E6BACD",
      name: "Radicchio",
    },
    23: {
      background: "#8e24aa",
      foreground: "#1d1d1d",
      fadedColor: "#DEBCE6",
      name: "Grape",
    },
    1: {
      background: "#795548",
      foreground: "#1d1d1d",
      fadedColor: "#D7CCC9",
      name: "Cocoa",
    },
  },
  event: {
    1: {
      background: "#a4bdfc",
      foreground: "#1d1d1d",
    },
    2: {
      background: "#7ae7bf",
      foreground: "#1d1d1d",
    },
    3: {
      background: "#dbadff",
      foreground: "#1d1d1d",
    },
    4: {
      background: "#ff887c",
      foreground: "#1d1d1d",
    },
    5: {
      background: "#fbd75b",
      foreground: "#1d1d1d",
    },
    6: {
      background: "#ffb878",
      foreground: "#1d1d1d",
    },
    7: {
      background: "#46d6db",
      foreground: "#1d1d1d",
    },
    8: {
      background: "#e1e1e1",
      foreground: "#1d1d1d",
    },
    9: {
      background: "#5484ed",
      foreground: "#1d1d1d",
    },
    10: {
      background: "#51b749",
      foreground: "#1d1d1d",
    },
    11: {
      background: "#dc2127",
      foreground: "#1d1d1d",
    },
  },
  primaryEventsColors,
  calendarColorsArray: [
    "#9e69af",
    "#f4511e",
    "#009688",
    "#3f51b5",
    "#ef6c00",
    "#7986cb",
    "#d81b60",
    "#e67c73",
    "#d50000",
    "#f09300",
    "#0b8043",
    "#7cb342",
    "#c0ca33",
    "#e4c441",
    "#f6bf26",
    "#33b679",
    "#039be5",
    "#4285f4",
    "#b39ddb",
    "#616161",
    "#a79b8e",
    "#ad1457",
    "#8e24aa",
    "#795548",
  ],
  colorToFadeColorIndex: {
    "#9e69af": {
      fadedColor: "#E1D2E6",
    },
    "#f4511e": {
      fadedColor: "#FCCCBB",
    },
    "#009688": {
      fadedColor: "#B3E0DB",
    },
    "#3f51b5": {
      fadedColor: "#C5CBE9",
    },
    "#ef6c00": {
      fadedColor: "#FAD3B3",
    },
    "#7986cb": {
      fadedColor: "#E4E7F3",
    },
    "#d81b60": {
      fadedColor: "#F3BBCE",
    },
    "#e67c73": {
      fadedColor: "#F8D8D5",
    },
    "#d50000": {
      fadedColor: "#F2B3B2",
    },
    "#f09300": {
      fadedColor: "#FADFB2",
    },
    "#0b8043": {
      fadedColor: "#B6D9C7",
    },
    "#7cb342": {
      fadedColor: "#D8E8C6",
    },
    "#c0ca33": {
      fadedColor: "#EDF0C2",
    },
    "#e4c441": {
      fadedColor: "#F7EDC6",
    },
    "#f6bf26": {
      fadedColor: "#FCEDBE",
    },
    "#33b679": {
      fadedColor: "#C2E9D7",
    },
    "#039be5": {
      fadedColor: "#B2E1F8",
    },
    "#4285f4": {
      fadedColor: "#C7D9FC",
    },
    "#b39ddb": {
      fadedColor: "#E8E1F4",
    },
    "#616161": {
      fadedColor: "#D0D0D0",
    },
    "#a79b8e": {
      fadedColor: "#E5E1DD",
    },
    "#ad1457": {
      fadedColor: "#E6BACD",
    },
    "#8e24aa": {
      fadedColor: "#DEBCE6",
    },
    "#795548": {
      fadedColor: "#D7CCC9",
    },
  },
  old_calendar_colors: {
    1: {
      background: "#ac725e",
      foreground: "#1d1d1d",
    },
    2: {
      background: "#d06b64",
      foreground: "#1d1d1d",
    },
    3: {
      background: "#f83a22",
      foreground: "#1d1d1d",
    },
    4: {
      background: "#fa573c",
      foreground: "#1d1d1d",
    },
    5: {
      background: "#ff7537",
      foreground: "#1d1d1d",
    },
    6: {
      background: "#ffad46",
      foreground: "#1d1d1d",
    },
    7: {
      background: "#42d692",
      foreground: "#1d1d1d",
    },
    8: {
      background: "#16a765",
      foreground: "#1d1d1d",
    },
    9: {
      background: "#7bd148",
      foreground: "#1d1d1d",
    },
    10: {
      background: "#b3dc6c",
      foreground: "#1d1d1d",
    },
    11: {
      background: "#fbe983",
      foreground: "#1d1d1d",
    },
    12: {
      background: "#fad165",
      foreground: "#1d1d1d",
    },
    13: {
      background: "#92e1c0",
      foreground: "#1d1d1d",
    },
    14: {
      background: "#9fe1e7",
      foreground: "#1d1d1d",
    },
    15: {
      background: "#9fc6e7",
      foreground: "#1d1d1d",
    },
    16: {
      background: "#4986e7",
      foreground: "#1d1d1d",
    },
    17: {
      background: "#9a9cff",
      foreground: "#1d1d1d",
    },
    18: {
      background: "#b99aff",
      foreground: "#1d1d1d",
    },
    19: {
      background: "#c2c2c2",
      foreground: "#1d1d1d",
    },
    20: {
      background: "#cabdbf",
      foreground: "#1d1d1d",
    },
    21: {
      background: "#cca6ac",
      foreground: "#1d1d1d",
    },
    22: {
      background: "#f691b2",
      foreground: "#1d1d1d",
    },
    23: {
      background: "#cd74e6",
      foreground: "#1d1d1d",
    },
    24: {
      background: "#a47ae2",
      foreground: "#1d1d1d",
    },
  },
  createColorCircle: createColorValueAndLabel(),
  createColorCircleTags: createColorValueAndLabel(null, true),
  calendarColors: [
    { color: "#ad1457", label: "Radicchio", colorId: "21" },
    { color: "#d81b60", label: "Cherry Blossom", colorId: "22" },
    { color: "#d50000", label: "Tomato", colorId: "3" },
    { color: "#e67c73", label: "Flamingo", colorId: "2" },
    { color: "#f4511e", label: "Tangerine", colorId: "4" },
    { color: "#ef6c00", label: "Pumpkin", colorId: "5" },
    { color: "#f09300", label: "Mango", colorId: "6" },
    { color: "#f6bf26", label: "Banana", colorId: "12" },
    { color: "#e4c441", label: "Citron", colorId: "11" },
    { color: "#c0ca33", label: "Avocado", colorId: "10" },
    { color: "#7cb342", label: "Pistachio", colorId: "9" },
    { color: "#33b679", label: "Sage", colorId: "13" },
    { color: "#0b8043", label: "Basil", colorId: "8" },
    { color: "#009688", label: "Eucalyptus", colorId: "7" },
    { color: "#039be5", label: "Peacock", colorId: "14" },
    { color: "#4285f4", label: "Cobalt", colorId: "15" },
    { color: "#3f51b5", label: "Blueberry", colorId: "16" },
    { color: "#7986cb", label: "Lavender", colorId: "17" },
    { color: "#b39ddb", label: "Wisteria", colorId: "18" },
    { color: "#9e69af", label: "Amethyst", colorId: "24" },
    { color: "#8e24aa", label: "Grape", colorId: "23" },
    { color: "#795548", label: "Cocoa", colorId: "1" },
    { color: "#616161", label: "Graphite", colorId: "19" },
    { color: "#a79b8e", label: "Birch", colorId: "20" },
  ],
};

export function createColorsListWithDefault(
  defaultPrimaryCalendarColor = DEFAULT_PRIMARY_CALENDAR_COLOR
) {
  const defaultColorObj = {
    name: "Default",
    color: defaultPrimaryCalendarColor,
    fadedColor: getFadedColor(defaultPrimaryCalendarColor),
    nickName: "default",
    id: "0",
  };
  return produce(primaryEventsColors, (draftState) => {
    draftState["0"] = defaultColorObj;
  });
}

export function getBackgroundHexFromGoogleColorID(colorID) {
  return {
    color: primaryEventsColors[colorID]?.color,
    fadedColor: primaryEventsColors[colorID]?.fadedColor,
  };
}

export function createColorsWithNickName({
  defaultColor,
  user,
  masterAccount,
  showNoColor = false,
}) {
  const colorLabels = getColorLabels({
    masterAccount,
    user,
  });

  const defaultColorObj = {
    name: "Default",
    color: defaultColor,
    fadedColor: getFadedColor(defaultColor),
    nickName: "default",
    id: "0",
  };

  const noColorObj = {
    name: "No Color",
    color: "#00FFFFFF",
    fadedColor: "#00FFFFFF",
    nickName: "None",
    id: "-1",
  };

  function isNoColor(colorObject) {
    return getColorIDFromColorObject(colorObject) === "-1";
  }

  function isDefaultColor(colorObject) {
    return getColorIDFromColorObject(colorObject) === "0";
  }

  // object key is colorID and value is color object (e.g. )
  // {
  //   "name": "Flamingo",
  //   "color": "#e67c73",
  //   "fadedColor": "#F8D8D5",
  //   "nickName": "Orange, pink",
  //   "id": "4"
  // }
  const inputColors = showNoColor ? tagColors : primaryEventsColors;
  const colors = produce(inputColors, (draftState) => {
    draftState["0"] = defaultColorObj;
    if (showNoColor) {
      draftState["-1"] = noColorObj;
    }
  });

  let defaultColorList = [];
  let colorLabelList = [];
  let allColors = [];
  Object.values(colors).forEach((colorObject) => {
    if (isDefaultColor(colorObject)) {
      allColors = allColors.concat(colorObject); // default color should be first
      return;
    }
    if (isNoColor(colorObject)) {
      defaultColorList = defaultColorList.concat(colorObject);
      return;
    }

    const matchingColorLabel = getMatchingColorLabel({
      colorLabels,
      colorID: getColorIDFromColorObject(colorObject),
    });
    if (matchingColorLabel) {
      colorLabelList = colorLabelList.concat({
        ...colorObject,
        name: getColorLabelLabel(matchingColorLabel),
      });
    } else {
      defaultColorList = defaultColorList.concat(colorObject);
    }
  });
  allColors = allColors.concat(colorLabelList).concat(defaultColorList);
  return allColors;
}

function getColorIDFromColorObject(colorObject) {
  return colorObject?.id;
}

function getColorNameFromColorObject(colorObject) {
  return colorObject?.name ?? "";
}

function getColorHexFromColorObject(colorObject) {
  return colorObject?.color ?? "";
}

export function getMatchingColorFromID({ colorList, colorID }) {
  const matchingColorObject = colorList.find(
    (color) => getColorIDFromColorObject(color) === colorID
  );
  return getColorHexFromColorObject(matchingColorObject);
}

export function createColorValueAndLabel(inputColors, isTag) {
  const defaultColors = isTag ? tagColors : primaryEventsColors;
  const colors = inputColors || defaultColors;

  return Object.values(colors).map((colorObject) => {
    return {
      value: getColorIDFromColorObject(colorObject),
      name: getColorNameFromColorObject(colorObject),
      color: getColorHexFromColorObject(colorObject),
      label: (
        <CircleWithColor
          color={getColorHexFromColorObject(colorObject)}
          colorName={getColorNameFromColorObject(colorObject)}
        />
      ),
    };
  });
}

export default googleColors;
