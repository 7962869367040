import { triggerRefreshWithOnlineCheck } from "../services/appFunctions";
import {
  saveLastRefreshFromIndexDBErrorTime,
  shouldRefreshFromIndexDBError,
} from "./localData";

export function handleIndexDBError({ error, message, context }) {
  // Internal error opening backing store for indexedDB.open
  const errorMessage = message ?? error?.message;
  if (!errorMessage || !errorMessage.includes) {
    return;
  }

  if (
    errorMessage.includes(
      "Internal error opening backing store for indexedDB.open"
    ) || errorMessage.includes("No available storage method found.")
  ) {
    if (!shouldRefreshFromIndexDBError()) {
      return false;
    }

    saveLastRefreshFromIndexDBErrorTime();
    triggerRefreshWithOnlineCheck();
  }
}
