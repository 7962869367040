import {
  differenceInSeconds,
  parseISO,
  differenceInCalendarDays,
  differenceInHours,
  addDays,
  nextDay,
  parse,
} from "date-fns";
import {
  isValidJSDate,
  convertDateIntoEpochUnixWeek,
  isAfterMinute,
  isValidTimeZone,
  handleError,
  getCurrentTimeInCurrentTimeZone,
  convertToTimeZone,
} from "../services/commonUsefulFunctions";
import { getEventStart } from "../services/eventResourceAccessors";
import { getEventEndValue, getEventStartValue } from "./eventFunctions";
import { MINUTE_IN_MS } from "../services/globalVariables";
import { isEmptyArray } from "./arrayFunctions";
import { devErrorPrint } from "../services/devFunctions";

const validDaysOfTheWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const DAY_OF_MONTH = {
  "1st": 1,
  "2nd": 2,
  "3rd": 3,
  "4th": 4,
  "5th": 5,
  "6th": 6,
  "7th": 7,
  "8th": 8,
  "9th": 9,
  "10th": 10,
  "11th": 11,
  "12th": 12,
  "13th": 13,
  "14th": 14,
  "15th": 15,
  "16th": 16,
  "17th": 17,
  "18th": 18,
  "19th": 19,
  "20th": 20,
  "21st": 21,
  "22nd": 22,
  "23rd": 23,
  "24th": 24,
  "25th": 25,
  "26th": 26,
  "27th": 27,
  "28th": 28,
  "29th": 29,
  "30th": 30,
  "31st": 31,
};

export const DATE_FSN_12_HOUR_FORMAT = "p"; // 12:00 AM

function minutesRemainingFromNow(event, timeZone) {
  const startTime = timeZone 
    ? convertToTimeZone(parseISO(event.defaultStartTime), {timeZone})
    : parseISO(event.defaultStartTime);
  const now = timeZone ? getCurrentTimeInCurrentTimeZone(timeZone) : new Date();
  return Math.ceil(differenceInSeconds(startTime, now) / 60);
}

export function timeRemainingBreakdown(event, timeZone) {
  const minutesRemaining = minutesRemainingFromNow(event, timeZone);

  if (minutesRemaining < 60) {
    return { minute: minutesRemaining, minutesRemaining };
  } else if (Math.floor(minutesRemaining / 60) < 24) {
    let hour = Math.floor(minutesRemaining / 60);
    let minute = minutesRemaining % 60;

    return { hour, minute, minutesRemaining };
  } else {
    // days left
    let day = Math.floor(minutesRemaining / 1440);
    let hour = Math.floor(minutesRemaining / 60) % 24;
    let minute = minutesRemaining % 60;

    return { day, hour, minute, minutesRemaining };
  }
}

export function isExpandedMultiDayEvent(e) {
  // spans across different days but beginning and end is less than 24 hours apart
  return (
    differenceInCalendarDays(e.eventStart, e.eventEnd) &&
    differenceInHours(e.eventEnd, e.eventStart) < 24
  );
}

export function formatToBasicISO(jsDate) {
  // https://github.com/date-fns/date-fns/discussions/2366#discussioncomment-532000
  // return format(jsDate, "yyyyMMdd'T'HHmmss'Z'");
  // https://github.com/date-fns/date-fns/issues/2151
  // return format(jsDate, "yyyy-MM-dd'T'HH:mm:ss'z'");
  // toISOString returns 2021-09-04T17:00:00.000Z
  if (!isValidJSDate(jsDate)) {
    return "";
  }

  return (
    jsDate.toISOString().split(".")[0]?.replace(/-/g, "").replace(/:/g, "") +
    "Z"
  );
}

export function getOriginalEventStartTimeDateUTC(e) {
  if (!getEventStart(e)) {
    return null;
  }

  return parseISO(getEventStartValue(e));
}

export function guardedParseISO(dateString) {
  try {
    const parsedDate = parseISO(dateString);
    if (!isValidJSDate(parsedDate)) {
      return null;
    }
    return parsedDate;
  } catch (error) {
    devErrorPrint(error, "guardedParseISO_error");
    return null;
  }
}

export function getDayOfMonthFromText(text) {
  if (!text || text.length < 3) {
    return false;
  }
  let loweredCase = text.toLowerCase();
  let dayOfMonth;
  let matchedText;

  Object.keys(DAY_OF_MONTH).forEach((d) => {
    let reg = new RegExp("\\b" + `${d}` + "\\b", "gmi");
    if (!dayOfMonth && reg.test(loweredCase)) {
      dayOfMonth = DAY_OF_MONTH[d];
      matchedText = d;
    }
  });

  return { matchedText, dayOfMonth };
}

export function createDateIndexForDB(e) {
  return convertDateIntoEpochUnixWeek(getEventStartValue(e));
}

export function safeGuardTimeZones(option) {
  // used to check if time zone is valid and if not then try to convert to safe time zone
  if (option?.timeZone) {
    const inputTimeZone = option.timeZone.toLowerCase();
    if (inputTimeZone.includes("gmt+") || inputTimeZone.includes("gmt-")) {
      // prevent bugs like "GMT-05:00"
      const splitArray = inputTimeZone.split(/[+-]+/); // split based on + -
      // list of valid time zones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      // for this case, need to create something like Etc/GMT-1
      const defaultGMTString = "Etc/GMT";

      let plusMinusHour = splitArray[1] || "";
      if (plusMinusHour.includes(":")) {
        plusMinusHour = plusMinusHour.substring(0, plusMinusHour.indexOf(":")); // remove : and everything after it ("5:00 -> 5")
      }

      // https://www.tutorialspoint.com/javascript-regex-to-remove-leading-zeroes-from-numbers
      plusMinusHour = plusMinusHour.replace(/\D|^0+/g, "").trim(); //remove leading 0's

      let timeZone = `${defaultGMTString}${
        inputTimeZone.includes("-") ? "-" : "+"
      }${plusMinusHour || "0"}`;
      return { ...option, timeZone };
    } else if (inputTimeZone.includes("gmt00:00")) {
      let timeZone = "Etc/GMT0";
      return { ...option, timeZone };
    }
  }
  return option;
}

export function getUtcOffset(timeZone) {
  try {
    const date = new Date();
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone: timeZone }));
    return (tzDate - utcDate) / MINUTE_IN_MS;
  } catch (error) {
    handleError(error);
    devErrorPrint(error, "getUtcOffset_error");
    return 0;
  }
}

export function getOffsetBetweenTimeZones(timeZone1, timeZone2) {
  const date = new Date();
  try {
    const timeZone1Date = new Date(
      date.toLocaleString("en-US", { timeZone: timeZone1 })
    );
    const timeZone2Date = new Date(
      date.toLocaleString("en-US", { timeZone: timeZone2 })
    );
    return (timeZone1Date - timeZone2Date) / MINUTE_IN_MS;
  } catch (error) {
    handleError(error);
    devErrorPrint(error, "getOffsetBetweenTimeZones_error");
    return 0;
  }
}

function isValidDayOfWeek(dayOfWeek) {
  return validDaysOfTheWeek.includes(dayOfWeek.toLowerCase());
}

export function findUpcomingDayOfWeek(dayOfWeek) {
  const currentDate = new Date();
  if (isValidDayOfWeek(dayOfWeek)) {
    const dayIndex = validDaysOfTheWeek.indexOf(dayOfWeek.toLowerCase());
    return nextDay(currentDate, dayIndex);
  } else if (dayOfWeek.toLowerCase().trim() === "today") {
    return currentDate;
  } else if (dayOfWeek.toLowerCase().trim() === "tomorrow") {
    return addDays(currentDate, 1);
  } else {
    return addDays(new Date(), 1);
  }
}

export function isSlotInFuture(slot) {
  return slot?.eventStart && isAfterMinute(slot.eventStart, new Date());
}

export function isStringInISO8601Format(str) {
  if (!str) {
    return false;
  }

  const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/;
  return iso8601Pattern.test(str);
}

export function extractDurationInMinutes(text) {
  if (!text) {
    return null;
  }

  const durationPattern = /for\s+(\d+)\s*(?:minutes?|mins?)\b/i;
  const hoursPattern = /for\s+(an hour|(\d+)\s+hour(?:s)?)/i;
  const hourAndHalfPattern = /for\s+an hour and a half/i;

  let minutes;

  if (durationPattern.test(text)) {
    const durationMatch = text.match(durationPattern);
    minutes = parseInt(durationMatch[1]);
  } else if (hoursPattern.test(text)) {
    const hoursMatch = text.match(hoursPattern);
    const hours = hoursMatch[1].toLowerCase();

    if (hours === "an hour") {
      minutes = 60;
    } else if (hoursMatch[2]) {
      const hoursValue = parseInt(hoursMatch[2]);
      minutes = hoursValue * 60;
    }
  } else if (hourAndHalfPattern.test(text)) {
    minutes = 90;
  }

  return minutes;
}

export function getISO8601StringOffSetInMinutes(isoString) {
  if (!isoString) {
    return null;
  }

  // Match the timezone offset (format: +hh:mm or -hh:mm)
  const timezonePartMatch = isoString.match(/([+-]\d{2}:\d{2})$/);

  // If we don't have a valid match, return null or throw an error
  if (!timezonePartMatch) {
    return null;
  }

  // Get the timezone part of the string
  const timezonePart = timezonePartMatch[0];

  // Split the hours and minutes
  let [hours, minutes] = timezonePart.slice(1).split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);
  const isNegative = timezonePart.startsWith("-");

  // Convert minutes to fraction of hours
  let offset = hours * 60 + minutes;

  // If the timezone is negative
  if (isNegative) {
    offset = -offset;
  }

  return offset;
}

export function parseOutlookAllDayEvent(event) {
  const rawStart = getEventStartValue(event);
  const rawEnd = getEventEndValue(event);
  if (!rawStart?.includes("T") || !rawEnd?.includes("T")) {
    return {
      eventStart: parseISO(getEventStartValue(event)),
      eventEnd: parseISO(getEventEndValue(event)),
    };
  }

  const startDate = rawStart.split("T")[0];
  const endDate = rawEnd.split("T")[0];
  if (!isValidDateFormat(startDate) || !isValidDateFormat(endDate)) {
    return {
      eventStart: parseISO(getEventStartValue(event)),
      eventEnd: parseISO(getEventEndValue(event)),
    };
  }

  return {
    eventStart: parse(startDate, "yyyy-MM-dd", new Date()),
    eventEnd: parse(endDate, "yyyy-MM-dd", new Date()),
  };
}

// checks if it matches "yyyy-MM-dd"
export function isValidDateFormat(dateString) {
  if (!dateString) {
    return false;
  }

  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(dateString);
}

export function filterOutInvalidTimeZones(timeZones) {
  if (!Array.isArray(timeZones) || isEmptyArray(timeZones)) {
    return timeZones;
  }
  return timeZones.filter((tz) => isValidTimeZone(tz));
}
