import mainCalendarBroadcast from "../broadcasts/mainCalendarBroadcast";
import settingsBroadcast from "../broadcasts/settingsBroadcast";
import { setLastSelectedDate } from "../lib/localData";
import { filterOutInvalidTimeZones } from "../lib/timeFunctions";
import { guessTimeZone } from "./commonUsefulFunctions";

export const REDUCER_TYPES = {
  REMOVE_CURRENT_PREVIEW_EVENT: "REMOVE_CURRENT_PREVIEW_EVENT",
  REMOVE_POPUP_EVENT: "REMOVE_POPUP_EVENT",
  SET_CREATE_AVAILABILITY_MODE: "SET_CREATE_AVAILABILITY_MODE",
  TOGGLE_AVAILABILITY_MODE: "TOGGLE_AVAILABILITY_MODE",
};

export function currentUser(state = {}, action) {
  switch (action.type) {
    case "STORE_USER_DATA":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      settingsBroadcast.publish("UPDATE_USER_LIST_IN_SETTINGS", action.data);
      return action.data;
    default:
      return state;
  }
}

export function templateSideBar(state = {}, action) {
  switch (action.type) {
    case "SET_TEMPLATE_SIDE_BAR":
      return action.data;
    case "REMOVE_TEMPLATE_SIDE_BAR":
      return {};
    default:
      return state;
  }
}

export function selectedDay(state = null, action) {
  switch (action.type) {
    case "SELECT_DAY":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      setLastSelectedDate(action.data);
      return action.data;
    default:
      return state;
  }
}

export function shouldShowGlobalKeyMap(state = false, action) {
  switch (action.type) {
    case "TOGGLE_SHOW_GLOBAL_KEY_MAP":
      return !state;
    case "SHOW_GLOBAL_KEY_MAP":
      return true;
    case "HIDE_GLOBAL_KEY_MAP":
      return false;
    default:
      return state;
  }
}

export function currentPreviewedEvent(state = null, action) {
  switch (action.type) {
    case "SET_PREVIEWED_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.uniqueEtag,
        action.data?.uniqueEtag,
      ]);
      return action.data;
    case REDUCER_TYPES.REMOVE_CURRENT_PREVIEW_EVENT:
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.uniqueEtag,
      ]);
      return null;
    default:
      return state;
  }
}

export function currentTimeZone(state = guessTimeZone(), action) {
  switch (action.type) {
    case "SET_TIME_ZONE":
      return action.data;
    default:
      return state;
  }
}

export function currentTimeZoneLabel(state = null, action) {
  switch (action.type) {
    case "SET_TIME_ZONE_LABEL":
      return action.data;
    default:
      return state;
  }
}

export function isEditModeOn(state = false, action) {
  switch (action.type) {
    case "SET_EDIT_MODE":
      return action.data;
    default:
      return state;
  }
}

// Change to false by default
export function isCreateAvailabilityModeOn(state = false, action) {
  switch (action.type) {
    case "SET_CREATE_AVAILABILITY_MODE":
      return action.data;
    case "TOGGLE_AVAILABILITY_MODE":
      return !state;
    default:
      return state;
  }
}

export function agendaDay(state = null, action) {
  switch (action.type) {
    case "SET_AGENDA_DAY":
      return action.data;
    default:
      return state;
  }
}

export function shouldDisplayMenu(state = false, action) {
  switch (action.type) {
    case "SET_SHOULD_DISPLAY_MENU":
      return action.data;
    default:
      return state;
  }
}

export function currentHoverEvent(state = null, action) {
  switch (action.type) {
    case "SET_CURRENT_HOVER_PREVIEWED_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.uniqueEtag,
        action.data?.uniqueEtag,
      ]);
      return action.data;
    case "REMOVE_CURRENT_HOVER_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.uniqueEtag,
      ]);
      return null;
    default:
      return state;
  }
}

export function templates(state = [], action) {
  switch (action.type) {
    case "CREATE_TEMPLATE":
      return state.concat(action.data);
    case "UPDATE_TEMPLATE":
      return state.filter((t) => t.id !== action.data.id).concat(action.data);
    case "REMOVE_TEMPLATE":
      return state.filter((t) => t.id !== action.data);
    case "STORE_TEMPLATE":
      return action.data;
    default:
      return state;
  }
}

export function defaultBrowserTimeZone(state = null, action) {
  switch (action.type) {
    case "STORE_DEFAULT_BROWSER_TIME_ZONE":
      return action.data;
    default:
      return state;
  }
}

export function temporaryEvents(state = null, action) {
  switch (action.type) {
    case "SET_TEMPORARY_EVENTS":
      return action.data;
    default:
      return state;
  }
}

export function isCommandCenterOn(state = null, action) {
  switch (action.type) {
    case "SET_IS_COMMAND_CENTER_ON":
      return action.data;
    default:
      return state;
  }
}

export function popupEvent(state = null, action) {
  switch (action.type) {
    case "SET_POPUP_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.event?.uniqueEtag,
        action?.data?.event?.uniqueEtag,
      ]);

      return action.data;
    case REDUCER_TYPES.REMOVE_POPUP_EVENT:
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.event?.uniqueEtag,
      ]);
      return null;
    default:
      return state;
  }
}

export function hoverPopupEvent(state = null, action) {
  switch (action.type) {
    case "SET_HOVER_POPUP_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.event?.uniqueEtag,
        action?.data?.event?.uniqueEtag,
      ]);

      return action.data;
    case "REMOVE_HOVER_POPUP_EVENT":
      mainCalendarBroadcast.publish("REMOVE_IDS_FROM_STYLE_INDEX", [
        state?.event?.uniqueEtag,
      ]);

      return null;
    default:
      return state;
  }
}

export function eventFormEmails(state = null, action) {
  switch (action.type) {
    case "SET_EVENT_FORM_EMAILS":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      return action.data;
    case "REMOVE_EVENT_FORM_EMAILS":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      return null;
    default:
      return state;
  }
}

export function temporarySecondaryCalendarColorsIndex(state = {}, action) {
  switch (action.type) {
    case "SET_TEMPORARY_SEARCHED_CALENDAR_COLORS_INDEX":
      return action.data;
    case "REMOVE_SECONDARY_CALENDAR_COLORS_INDEX":
      return {};
    default:
      return state;
  }
}

export function emailToNameIndex(state = {}, action) {
  // {email: name} used to provide display user's names based on email
  switch (action.type) {
    case "ADD_EMAIL_TO_EMAIL_NAME_INDEX":
      return action.data;
    default:
      return state;
  }
}

export function shouldShowTopBar(state = false, action) {
  switch (action.type) {
    case "SET_SHOULD_SHOW_TOP_BAR":
      return action.data;
    default:
      return state;
  }
}

export function isMac(state = true, action) {
  switch (action.type) {
    case "SET_IS_MAC":
      return action.data;
    default:
      return state;
  }
}

export function shouldShowShortcutsLegend(state = false, action) {
  switch (action.type) {
    case "DISPLAY_SHORTCUTS_LEGEND":
      return true;
    case "HIDE_SHORTCUTS_LEGEND":
      return false;
    default:
      return state;
  }
}

export function isDarkMode(state = false, action) {
  switch (action.type) {
    case "TURN_ON_DARK_MODE":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      return true;
    case "TURN_OFF_DARK_MODE":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      return false;
    default:
      return state;
  }
}

export function availabilitySelectedMinutes(state = 30, action) {
  switch (action.type) {
    case "SET_AVAILABILITY_SELECTED_MINUTES":
      return action.data;
    default:
      return state;
  }
}

export function weekStart(state = 0, action) {
  switch (action.type) {
    case "SET_WEEK_START":
      return action.data;
    default:
      return state;
  }
}

export function shouldOnlyShowWorkWeek(state = false, action) {
  switch (action.type) {
    case "SET_ONLY_SHOW_WORK_WEEK":
      return action.data;
    default:
      return state;
  }
}

export function lastEventFormState(state = null, action) {
  switch (action.type) {
    case "SET_LAST_EVENT_FORM_STATE":
      return action.data;
    default:
      return state;
  }
}

export function showDeclinedEvents(state = true, action) {
  switch (action.type) {
    case "SET_SHOW_DECLINED_EVENTS":
      return action.data;
    default:
      return state;
  }
}

export function isDuplicateEvent(state = false, action) {
  switch (action.type) {
    case "DUPLICATE_EVENT":
      return action.data;
    default:
      return state;
  }
}

export function recentTags(state = [], action) {
  // this can go into another redux library like the ones that paul recommended
  switch (action.type) {
    case "SET_PAINT_ORDER":
      return action.data;
    default:
      return state;
  }
}

export function selectedCalendarView(state = 7, action) {
  switch (action.type) {
    case "SET_CUSTOM_NUMBER_OF_DAYS":
      mainCalendarBroadcast.publish("WIPE_MAIN_CALENDAR_STYLES");
      return action.data;
    default:
      return state;
  }
}

export function format24HourTime(state = false, action) {
  switch (action.type) {
    case "SET_FORMAT_24_HOUR_TIME":
      return action.data;
    default:
      return state;
  }
}

export function dateFieldOrder(state = "MDY", action) {
  switch (action.type) {
    case "SET_DATE_FIELD_ORDER":
      return action.data;
    default:
      return state;
  }
}

export function isMenuBarDarkMode(state = false, action) {
  switch (action.type) {
    case "SET_IS_MENU_BAR_DARK_MODE":
      return action.data;
    default:
      return state;
  }
}

export function personalLinks(state = [], action) {
  switch (action.type) {
    case "SET_PERSONAL_LINKS":
      return action.data;
    default:
      return state;
  }
}

export function originalRecurrenceEventIndex(state = {}, action) {
  switch (action.type) {
    case "SET_ORIGINAL_RECURRENCE_EVENT_INDEX":
      return action.data;
    default:
      return state;
  }
}

export function anchorTimeZones(state = [], action) {
  switch (action.type) {
    case "SET_ANCHOR_TIME_ZONES":
      return filterOutInvalidTimeZones(action.data);
    default:
      return state;
  }
}

export function temporaryTimeZones(state = [], action) {
  switch (action.type) {
    case "SET_TEMPORARY_TIME_ZONES":
      return filterOutInvalidTimeZones(action.data);
    default:
      return state;
  }
}

export function isMobileView(state = false, action) {
  switch (action.type) {
    case "SET_IS_MOBILE_VIEW":
      return action.data;
    default:
      return state;
  }
}

export function isCreateFocusModeBlocks(state = false, action) {
  switch (action.type) {
    case "SET_CREATE_FOCUS_MODE_BLOCKS":
      return action.data;
    case "REMOVE_CREATE_FOCUS_MODE_BLOCKS":
      return false;
    default:
      return state;
  }
}
