import { devErrorPrint } from "../services/devFunctions";

export function makeJsonParsable(jsonString: string) {
  // remove any unnecessary white space
  jsonString = jsonString.trim();

  // remove trailing commas from objects and arrays
  jsonString = jsonString.replace(/,(?=[\]}])/g, '');

  // return the updated string
  return jsonString;
}

export function safeJSONParse<T = unknown>(jsonString: string | null) {
  if (!jsonString) {
    return null;
  }

  try {
    // since if it's not a proper json string, this will crash the app
    return JSON.parse(jsonString) as T;
  } catch (error) {
    devErrorPrint(error, jsonString);
    return null;
  }
}

export function safeJSONStringify(json: any) {
  if (!json) {
    return null;
  }

  try {
    // since if it's not a proper json string, this will crash the app
    return JSON.stringify(json);
  } catch (error) {
    devErrorPrint(error, json);
    return null;
  }
}
