import React from "react";
import { Trash2, Edit2 } from "react-feather";
import classNames from "classnames";
import { Plus } from "react-feather";
import {
  ALL_DEFAULT_CUSTOM_QUESTIONS,
  doesListOfQuestionsIncludeCompany,
  isCustomQuestionCompany,
  isNameOrEmailQuestion,
} from "../lib/availabilityFunctions";
import DefaultSwitch from "./defaultSwitch";

export enum CustomQuestionType {
  SingleLine = "singleLine",
  Multiline = "multiline",
  PhoneNumber = "phoneNumber",
}

export enum ModifyCustomQuestionMode {
  Edit = "edit",
  Add = "add",
}

export interface CustomQuestion {
  type: CustomQuestionType;
  required: boolean;
  on?: boolean;
  description: string;
}

export interface QuestionTypePreviewProps {
  type: CustomQuestionType;
  disabled?: boolean;
}

export interface CustomQuestionsProps {
  questions: Array<CustomQuestion>;
  addQuestion: () => void;
  deleteCustomQuestion: (question: CustomQuestion) => void;
  showModifyQuestionModal: (mode: ModifyCustomQuestionMode) => void;
  onSelectIndex: (index: number) => void;
  isAvailabilitySettings: boolean;
  setQuestions: (any) => void;
  showWarningForCompany: boolean;
}

export const DEFAULT_CUSTOM_QUESTIONS = [
  {
    description: "Name",
    required: true,
    type: CustomQuestionType.SingleLine,
  },
  {
    description: "Email",
    required: true,
    type: CustomQuestionType.SingleLine,
  },
];

const QuestionTypePreview: React.FC<QuestionTypePreviewProps> = ({
  type,
  disabled = false,
}) => {
  switch (type) {
    case CustomQuestionType.SingleLine:
      return (
        <div className="custom-question-single-line custom-question-preview" />
      );
    case CustomQuestionType.Multiline:
      return (
        <div className="custom-question-multi-line custom-question-preview" />
      );
    case CustomQuestionType.PhoneNumber:
      return (
        <input
          className="custom-question-single-line custom-question-preview default-font-size font-weight-300"
          style={{ paddingLeft: 10, width: "100%" }}
          placeholder="e.g. 415-123-4567"
        />
      );
    default:
      return null;
  }
};

function getAllDefaultQuestions(questions) {
  return questions.filter((question) =>
    Object.values(ALL_DEFAULT_CUSTOM_QUESTIONS).includes(question.description)
  );
}

function getAllQuestions(userQuestions) {
  let defaultQuestions = getAllDefaultQuestions(userQuestions);
  const otherQuestions =
    userQuestions.filter(
      (question) =>
        !Object.values(ALL_DEFAULT_CUSTOM_QUESTIONS).includes(
          question.description
        )
    ) ?? [];
  if (!doesListOfQuestionsIncludeCompany(defaultQuestions)) {
    defaultQuestions = defaultQuestions.concat({
      type: CustomQuestionType.SingleLine,
      required: true,
      description: ALL_DEFAULT_CUSTOM_QUESTIONS.COMPANY,
    });
  }
  return defaultQuestions.concat(otherQuestions);
}

export const CustomQuestions: React.FC<CustomQuestionsProps> = ({
  questions,
  showModifyQuestionModal,
  onSelectIndex,
  deleteCustomQuestion,
  isAvailabilitySettings,
  setQuestions,
  showWarningForCompany,
}) => {
  const allQuestions = getAllQuestions(questions);
  return (
    <div className="display-flex-flex-direction-row align-items-center margin-top-ten">
      <div
        className="pb-5"
        style={{ width: isAvailabilitySettings ? "100%" : "21rem" }}
      >
        {allQuestions.map((question, index) => {
          // We expect the first two custom questions to be name and email, which should be unmodifiable
          if (isCustomQuestionCompany(question)) {
            return (
              <div key={index} className="pb-6">
                <div
                  className={classNames(
                    "default-font-size mb-2",
                    "flex justify-between",
                    "w-60"
                  )}
                >
                  {`${ALL_DEFAULT_CUSTOM_QUESTIONS.COMPANY}*`}

                  <div className="flex items-center">
                    <div className="secondary-text-color default-font-size mr-2">
                      Show
                    </div>
                    <DefaultSwitch
                      disabled={false}
                      tabIndex={undefined}
                      id={"toggle-show-company"}
                      isChecked={doesListOfQuestionsIncludeCompany(questions)}
                      onChange={(isOn) => {
                        if (isOn) {
                          const updatedQuestions = questions.filter(
                            (q) => !isCustomQuestionCompany(q)
                          );
                          updatedQuestions.splice(2, 0, {
                            type: CustomQuestionType.SingleLine,
                            required: true,
                            description: ALL_DEFAULT_CUSTOM_QUESTIONS.COMPANY,
                          });
                          if (setQuestions) {
                            setQuestions(updatedQuestions);
                          }
                        } else {
                          const updatedQuestions = questions.filter(
                            (q) =>
                              q.description !==
                              ALL_DEFAULT_CUSTOM_QUESTIONS.COMPANY
                          );
                          if (setQuestions) {
                            setQuestions(updatedQuestions);
                          }
                        }
                      }}
                      additionalClassNames={""}
                    />
                  </div>
                </div>
                <QuestionTypePreview type={question.type} disabled />
                {showWarningForCompany ? (
                  <div className="warning-color default-font-size mt-1">
                    This variable was used in the title for booked meetings.
                    Please check the Meeting Details section for potential
                    errors.
                  </div>
                ) : null}
              </div>
            );
          }

          if (isNameOrEmailQuestion(question)) {
            return (
              <div key={index} className="pb-6">
                <div className="default-font-size mb-2">
                  {question.description}
                  {question.required ? " *" : ""}
                </div>
                <QuestionTypePreview type={question.type} disabled />
              </div>
            );
          } else {
            return (
              <div
                key={index}
                className="pb-6 custom-question-tile"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  onSelectIndex(index);
                  showModifyQuestionModal(ModifyCustomQuestionMode.Edit);
                }}
              >
                <div className="display-flex-flex-direction-row">
                  <div className="custom-question-description mb-2 cursor-pointer default-font-size mr-5">
                    {question.description}
                    {question.required ? " *" : ""}
                  </div>
                  <div className="display-flex-flex-direction-row justify-between">
                    <Edit2
                      className="clickable-icon margin-right-4 custom-question-trashcan"
                      size="14"
                    />
                    <div
                      style={{ zIndex: 10, marginLeft: "10px" }}
                      onClick={(e) => {
                        deleteCustomQuestion(question);
                        e.stopPropagation();
                      }}
                    >
                      <Trash2
                        className="clickable-icon margin-right-5 custom-question-trashcan"
                        size="14"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <QuestionTypePreview type={question.type} />
                </div>
              </div>
            );
          }
        })}

        <div
          className={classNames(
            "add-new-question-button",
            isAvailabilitySettings ? "" : "side-panel-modal-background-color",
            "hoverable-secondary-text-color"
          )}
          onClick={() => showModifyQuestionModal(ModifyCustomQuestionMode.Add)}
        >
          <Plus size={12} className="margin-top-0px-override mr-1" />

          <div className="default-font-size add-custom-question-label select-none">
            Add New Question
          </div>
        </div>
      </div>
    </div>
  );
};
