import { LOCAL_DATA_ACTION } from "../lib/localData";
import { localData } from "./commonUsefulFunctions";

export const VERSION_1 = "v1";
export const VERSION_2 = "v2";

const APP_VERSION = "app_version";

export function getAppVersion() {
  // return VERSION_1;
  return VERSION_2;
}

export function isVersionV2() {
  return getAppVersion() === VERSION_2;
}

function getStoredAppVersion() {
  return localData(LOCAL_DATA_ACTION.GET, APP_VERSION, getAppVersion());
}

export function isStoredVersionV2() {
  return getStoredAppVersion() === VERSION_2;
}

export function isStoredVersionV1() {
  return getStoredAppVersion() === VERSION_1;
}

export function storeAppVersionV1() {
  // only store if version is v1 and no version has been stored
  // used for migration
  if (isVersionV2()) {
    return;
  }

  const existingVersion = getStoredAppVersion();
  if (!existingVersion) {
    localData(LOCAL_DATA_ACTION.SET, APP_VERSION, getAppVersion());
  }
}
