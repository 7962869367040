import { useAllCalendars, useMasterAccount } from "../services/stores/SharedAccountData";

export function getAllCalendarsFromStore() {
  if (!useAllCalendars?.getState) {
    return null;
  }
  return useAllCalendars.getState()?.allCalendars;
}

export function getMasterAccountFromStore() {
  if (!useMasterAccount?.getState) {
    return null;
  }
  return useMasterAccount.getState()?.masterAccount;
}
