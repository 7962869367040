global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import {
  CONFERENCING_OPTIONS_ARRAY,
  CONFERENCING_OPTIONS_OBJECT,
  CONFERENCING_ICONS,
  ZOOM_UNIQUE,
  ZOOM_PERSONAL_LINK,
} from "../services/globalVariables";
import { constructRequestURL } from "../services/api";
import {
  ZOOM_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION,
  WHATS_APP_CONFERENCING_OPTION,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_CUSTOM_CONFERENCING,
  BACKEND_HANGOUT,
  BACKEND_ZOOM,
  ZOOM_STRING,
  PHONE_NUMBER_CONFERENCE,
  WHATS_APP_STRING,
  BACKEND_NO_CONFERENCING,
  GOOGLE_HANGOUT_STRING,
  NO_CONFERENCE_STRING,
  CUSTOM_CONFERENCING_STRING,
} from "../services/googleCalendarService";
import PhoneNumber from "awesome-phonenumber";
import { getEventConferenceData } from "../services/eventResourceAccessors";
import { determineOutlookAllowedConferencing, isOutlookUser } from "./outlookFunctions";
import { getDelegatedZoomPMI, isUserBeingScheduledFor } from "../services/maestroFunctions";
import { getGoogleCalendarAllowedConferenceType } from "../services/calendarAccessors";
import { getUserPrimaryCalendar } from "./calendarFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { isEmptyObjectOrFalsey, isTypeString } from "../services/typeGuards";
import { ENVIRONMENTS } from "./vimcalVariables";
import { getCustomConferencingName, getCustomConferencingURL, getDefaultPhoneOption, getDefaultUserConferencing, getDefaultZoomMode } from "./settingsFunctions";
import { isUrl } from "./stringFunctions";

export const GENERIC_ZOOM_ERROR = "GENERIC_ZOOM_ERROR";
export const REAUTH_ZOOM_ERROR = "REAUTH_ZOOM_ERROR";

export const ZOOM = "Zoom";
export const GOOGLE_MEET = "Google Meet";
export const PHONE = "Phone";
export const CUSTOM_CONFERENCING = "Custom";
export const OTHER_SETTINGS = "Other settings";
export const OUTLOOK_SETTINGS_LABEL = "Outlook";

const PROD_REDIRECT_URI_PROD =
  "https://prod.vimcal.com/app/v1/zoom_access_token";
const STAGING_REDIRECT_URI_PROD =
  "https://staging.vimcal.com/app/v1/zoom_access_token";
const DOGFOOD_REDIRECT_URI_PROD = "https://dogfood.vimcal.com/app/v1/zoom_access_token";
const LOCAL_REDIRECT_URI = "http://localhost:3001/app/v1/zoom_access_token";
const DEFAULT_REDIRECT_URI = constructRequestURL("zoom_access_token");
const PROD_CLIENT_ID = "igC_uBDvTLODyR3wrKofJA";
const STAGING_CLIENT_ID = "TBll8cGcRk6cYivWvnxS3Q";
const DEFAULT_CLIENT_ID =
  process.env.REACT_APP_ZOOM_CLIENT_ID || PROD_CLIENT_ID;
const DEFAULT_ZOOM_URL_AND_CLIENT_ID = { redirectUri: DEFAULT_REDIRECT_URI, clientId: DEFAULT_CLIENT_ID };

const DEFAULT = "default";

/* Set zoom env */
/* Must test on staging and SET BACK TO DEFAULT WHEN DONE */
// const ENV = ENVIRONMENTS.STAGING;
// const ENV = ENVIRONMENTS.PRODUCTION;
// const ENV = ENVIRONMENTS.DOGFOOD;
// const ENV = ENVIRONMENTS.LOCAL;
const ENV = DEFAULT;

export function determineZoomAuthInfo() {
  switch (ENV) {
    case ENVIRONMENTS.STAGING:
      return {
        redirectUri: STAGING_REDIRECT_URI_PROD,
        clientId: STAGING_CLIENT_ID,
      };
    case ENVIRONMENTS.PRODUCTION:
      return { redirectUri: PROD_REDIRECT_URI_PROD, clientId: PROD_CLIENT_ID };
    case ENVIRONMENTS.LOCAL:
      //if not using docker-> have to change the redirectUri for testing to whatever the redirecturl is below
      return { redirectUri: LOCAL_REDIRECT_URI, clientId: STAGING_CLIENT_ID };
    case ENVIRONMENTS.DOGFOOD:
      return { redirectUri: DOGFOOD_REDIRECT_URI_PROD, clientId: STAGING_CLIENT_ID }
    default:
      return DEFAULT_ZOOM_URL_AND_CLIENT_ID;
  }
}

export function determineDefaultConferencing(currentUser) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return BACKEND_HANGOUT;
  }

  return getDefaultUserConferencing({ user: currentUser }) || BACKEND_HANGOUT;
}

export const determineConferencingType = (string) => {
  if (!string || !isTypeString(string)) {
    return null;
  }
  const lowerCase = string.toLowerCase();

  return CONFERENCING_OPTIONS_ARRAY.find((conferencingType) =>
    lowerCase.includes(conferencingType)
  );
};

export const determineConferenceText = (string) => {
  if (!string) {
    return null;
  }

  return CONFERENCING_OPTIONS_OBJECT[determineConferencingType(string)];
};

export const conferenceIconUrl = (conferencing) =>
  CONFERENCING_ICONS[conferencing];

export function isDefaultZoomPersonalLink(currentUser) {
  return getDefaultZoomMode({ user: currentUser }) === ZOOM_PERSONAL_LINK;
}

export function isUserLoggedIntoZoom(user) {
  return user?.has_zoom_access ?? false;
}

export function getZoomPersonalLink({user, schedulers = {}, masterAccount}) {
  if (isEmptyObjectOrFalsey(user) && isEmptyObjectOrFalsey(schedulers) && isUserBeingScheduledFor({ user, schedulers, masterAccount })) {
    return null;
  }

  const delegatedPMI = getDelegatedZoomPMI({ user, schedulers });

  return (
    user.zoom_link ||
    (delegatedPMI
      ? `https://zoom.us/j/${delegatedPMI}`
      : null)
  );
}

export function getZoomMeetingDetails(zoomMeeting) {
  if (isEmptyObjectOrFalsey(zoomMeeting)) {
    return { phoneInfo: null };
  }

  let phoneInfo;
  if (
    zoomMeeting &&
    zoomMeeting.settings &&
    zoomMeeting.settings.global_dial_in_numbers &&
    zoomMeeting.settings.global_dial_in_numbers.length > 0
  ) {
    phoneInfo = zoomMeeting.settings.global_dial_in_numbers[0];
  }

  return { phoneInfo };
}

export function createConferencingOptions(currentUser) {
  if (isEmptyObjectOrFalsey(currentUser)) {
    return [
      ZOOM_CONFERENCING_OPTION,
      HANGOUT_CONFERENCING_OPTION,
      PHONE_CONFERENCING_OPTION,
      NO_CONFERENCING_OPTION,
    ];
  }

  let conferenceOptions = [
    ZOOM_CONFERENCING_OPTION,
    HANGOUT_CONFERENCING_OPTION,
    NO_CONFERENCING_OPTION,
  ];

  switch (getDefaultPhoneOption({ user: currentUser })) {
    case BACKEND_PHONE:
      conferenceOptions.splice(2, 0, PHONE_CONFERENCING_OPTION);
      break;
    case BACKEND_WHATS_APP:
      conferenceOptions.splice(2, 0, WHATS_APP_CONFERENCING_OPTION);
      break;
    default:
      // default to phone
      conferenceOptions.splice(2, 0, PHONE_CONFERENCING_OPTION);
  }

  if (getCustomConferencingURL({ user: currentUser })) {
    conferenceOptions.splice(3, 0, createCustomConferencingOption(currentUser));
  }

  return conferenceOptions;
}

export function createCustomConferencingOption(currentUser) {
  return {
    label: getCustomConferencingName({ user: currentUser }),
    value: BACKEND_CUSTOM_CONFERENCING,
  };
}

export function isPhoneConferencingWhatsApp(currentUser) {
  return (
    getDefaultPhoneOption({ user: currentUser }) === BACKEND_WHATS_APP
  );
}

export function convertConferencingStringToBackendValue(
  conferencingString,
  currentUser
) {
  if (
    [
      BACKEND_WHATS_APP,
      BACKEND_PHONE,
      BACKEND_ZOOM,
      BACKEND_HANGOUT,
      BACKEND_CUSTOM_CONFERENCING,
      BACKEND_NO_CONFERENCING,
    ].includes(conferencingString)
  ) {
    return conferencingString;
  }

  switch (conferencingString) {
    case GOOGLE_HANGOUT_STRING:
      return BACKEND_HANGOUT;
    case ZOOM_STRING:
      return BACKEND_ZOOM;
    case PHONE_NUMBER_CONFERENCE:
      return BACKEND_PHONE;
    case WHATS_APP_STRING:
      return BACKEND_WHATS_APP;
    case getCustomConferencingName({ user: currentUser}):
      return BACKEND_CUSTOM_CONFERENCING;
    case NO_CONFERENCE_STRING:
      return BACKEND_NO_CONFERENCING;
    default:
      return conferencingString;
  }
}

export function isValidPhoneConferencing(currentUser) {
  return (
    !isEmptyObjectOrFalsey(currentUser) &&
    !!currentUser.phone_number &&
    !!currentUser.phone_region_code
  );
}

export function detectCustomConferencingErrors(url, name) {
  if (!isUrl(url)) {
    return "Please enter a valid URL";
  }

  if (!url.includes("https")) {
    return "The URL above needs to include https://";
  }

  if (!name || name.trim().length === 0) {
    return "Please enter a valid conferencing name";
  }

  return null;
}

export function isValidConferencing(currentUser) {
  if (getDefaultUserConferencing({ user: currentUser }) !== BACKEND_CUSTOM_CONFERENCING) {
    return true; //return true if it's not custom conferencing
  }

  return isValidCustomConferencing(currentUser);
}

export function isValidCustomConferencing(currentUser) {
  return (
    getCustomConferencingURL({ user: currentUser }) &&
    getCustomConferencingName({ user: currentUser})
  );
}

export function isTemplatePhoneConferencing(conferenceData) {
  return (
    (conferenceData &&
      conferenceData.isTemplate &&
      conferenceData.conferenceType === PHONE_NUMBER_CONFERENCE) ||
    (conferenceData &&
      conferenceData.entryPoints &&
      conferenceData.entryPoints[0]?.entryPointType === "phone")
  );
}

export function isTemplateWhatsAppConferencing(conferenceData) {
  return (
    (conferenceData &&
      conferenceData.isTemplate &&
      conferenceData.conferenceType === WHATS_APP_STRING) ||
    (conferenceData &&
      conferenceData.entryPoints &&
      conferenceData.entryPoints[0]?.entryPointType === "video" &&
      conferenceData.entryPoints[0]?.label.includes("WhatsApp"))
  );
}

export function isTemplateCustomConferencing(conferenceData, currentUser) {
  return (
    (conferenceData &&
      conferenceData.isTemplate &&
      conferenceData.conferenceType === CUSTOM_CONFERENCING_STRING) ||
    (conferenceData &&
      conferenceData.entryPoints &&
      conferenceData.entryPoints[0]?.label?.includes(
        getCustomConferencingName({ user: currentUser})
      ))
  );
}

export function renderConferencingActionText(conferenceUrl, event) {
  let conferencingType = determineConferencingType(conferenceUrl);
  if (conferencingType) {
    return `Join ${
      determineConferenceText(conferencingType) || "video conference"
    }`;
  } else if (isConferencingPhone(conferenceUrl)) {
    return "Join phone call";
  } else if (getEventConferenceData(event)?.conferenceSolution?.name) {
    const name = getEventConferenceData(event).conferenceSolution.name;
    if (name.toLowerCase().includes("join")) {
      return name;
    }

    return "Join " + name;
  } else {
    return "Join video conference";
  }
}

export function isConferencingPhone(conferenceUrl) {
  if (!conferenceUrl || conferenceUrl.length === 0) {
    return false;
  }

  let formattedConferenceUrl = conferenceUrl
    .replace("tel:", "")
    .replace("-", "");
  let countryCode = PhoneNumber(formattedConferenceUrl).getRegionCode();

  let pn = new PhoneNumber(formattedConferenceUrl, countryCode);

  return pn.isValid();
}

// Gets conferencing options user can use since we can't pop up another modal
// Used for group vote and and personal links
export function getValidUserConferencingOptions({ 
  allCalendars = {}, 
  currentUser, 
  defaultOptions, 
  isUserBeingScheduledFor
}) {
  let eventConferencingOptions = defaultOptions ?? [];

  if (currentUser.zoom_link || isUserBeingScheduledFor) {
    eventConferencingOptions = eventConferencingOptions.concat(
      ZOOM_CONFERENCING_OPTION
    );
  }

  if (isValidPhoneConferencing(currentUser)) {
    if (getDefaultPhoneOption({ user: currentUser }) === BACKEND_WHATS_APP) {
      eventConferencingOptions = eventConferencingOptions.concat(
        WHATS_APP_CONFERENCING_OPTION
      );
    } else {
      eventConferencingOptions = eventConferencingOptions.concat(
        PHONE_CONFERENCING_OPTION
      );
    }
  }

  if (getCustomConferencingURL({ user: currentUser })) {
    eventConferencingOptions = eventConferencingOptions.concat(
      createCustomConferencingOption(currentUser)
    );
  }

  if (isOutlookUser(currentUser)) {
    const filteredConferencingOptionsForGoogleOptions = eventConferencingOptions
      .filter(option => option.value !== HANGOUT_CONFERENCING_OPTION.value);

    if (isEmptyObjectOrFalsey(allCalendars)) {
      return filteredConferencingOptionsForGoogleOptions;
    }

    const allowedOutlookConferencingOption = determineOutlookAllowedConferencing(allCalendars, currentUser.email);
    if (allowedOutlookConferencingOption?.length > 0) {
      eventConferencingOptions = filteredConferencingOptionsForGoogleOptions
        .concat(allowedOutlookConferencingOption);
    }
  } else {
    // google user
    // filter out google hangout/meet if user does not have access to meetings
    const userPrimaryCalendar = getUserPrimaryCalendar({ allCalendars, email: currentUser.email });
    if (!getGoogleCalendarAllowedConferenceType(userPrimaryCalendar)) {
      return eventConferencingOptions
        .filter(option => option.value !== HANGOUT_CONFERENCING_OPTION.value);
    }
  }

  return eventConferencingOptions;
}

export function isZoomFromGSuiteIntegration(event) {
  const eventConferenceData = getEventConferenceData(event);
  if (isEmptyObjectOrFalsey(eventConferenceData)) {
    return false;
  }

  const conferencingName = eventConferenceData.conferenceSolution?.name;
  if (!conferencingName) {
    return false;
  }

  return (
    conferencingName.toLowerCase().includes("zoom") &&
    eventConferenceData.entryPoints?.length > 0
  );
}

export function createOutlookPersonalLinkDescription(zoomPersonalLink) {
  return `Join Zoom Meeting\n${zoomPersonalLink}`;
}

export function createOutlookUniqueZoomDescription(zoomData) {
  const { 
    join_url, 
    password,
    id,
    settings
  } = zoomData;

  return [
    'Join Zoom Meeting',
    join_url,
    `Meeting ID: ${id}`,
    `Password: ${password}`,
    getZoomOneTapDial(zoomData),
    getZoomGlobalDialInNumbers(settings?.global_dial_in_numbers),
    `Meeting ID: ${id}`,
    'Find your local number: https://us06web.zoom.us/u/kcac9diqY',
  ].join("\n\n");
}

function getZoomOneTapDial(zoomData) {
  const {
    password,
    id,
    settings
  } = zoomData;
  if (!settings) {
    return "";
  }

  const {
    global_dial_in_numbers
  } = settings;

  if (isEmptyArray(global_dial_in_numbers)) {
    return "";
  }

  let oneTapDial = "One tap mobile\n";
  global_dial_in_numbers.forEach((dialIn, index) => {
    if (index > 1) {
      //only show 2 one tap dials
      return;
    }

    const {
      number,
      city,
      country
    } = dialIn;
    oneTapDial = oneTapDial + `${number.replace("+", "")},,${id},,,,*${password}# ${country}${city ? ` (${city})` : ""}\n`;
  });

  return oneTapDial;
}

function getZoomGlobalDialInNumbers(dialInArray) {
  if (!dialInArray || dialInArray.length === 0) {
    return ""
  }

  const createDialInString = (dialIn) => {
    const {
      number,
      city,
      country
    } = dialIn;
    let cityDialIn = `${number} ${country}`
    if (city) {
      cityDialIn = cityDialIn+ ` (${city})`;
    }

    return cityDialIn + "\n";
  }

  let dialInNumbers = "Dial by location\n";
  dialInArray.forEach(dialIn => {
    const cityDialIn = createDialInString(dialIn);
    dialInNumbers = dialInNumbers + cityDialIn;
  });

  return dialInNumbers;
}
