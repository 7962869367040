import create from "zustand";
import { persist, subscribeWithSelector } from "zustand/middleware";
import mainCalendarBroadcast from "../../broadcasts/mainCalendarBroadcast";

export const useIsMouseMoving = create(
  subscribeWithSelector((set) => ({
    isMouseMoving: false,
    setIsMouseMoving: (isMouseMoving) => set((state) => ({ isMouseMoving })),
  }))
);

export const useTabID = create(
  persist(
    (set) => ({
      tabID: null,
      setTabID: (tabID) => {
        set((state) => ({ tabID }));
      },
    }),
    {
      name: "tab-id-storage",
    }
  )
);

// way to track what the user selected as the left most time zone
export const useAppTimeZones = create(
  persist(
    (set) => ({
      orderedTimeZones: [],
      setOrderedTimeZones: (orderedTimeZones) => {
        set((state) => ({
          orderedTimeZones,
          lastOrderedTimeZonesUpdatedAt: new Date().toISOString(),
        }));
      },
      lastSelectedTimeZone: null,
      lastOrderedTimeZonesUpdatedAt: null,
      setLastSelectedTimeZone: (lastSelectedTimeZone) => {
        set((state) => ({ lastSelectedTimeZone }));
      },
      resetLastSelectedTimeZone: () => {
        set((state) => ({ lastSelectedTimeZone: null }));
      },
      resetTimeZoneData: () => {
        set((state) => ({
          lastSelectedTimeZone: null,
          orderedTimeZones: [],
          lastOrderedTimeZonesUpdatedAt: null,
        }));
      },
    }),
    {
      name: "time-zone-storage",
    }
  )
);

export const useHideRightHandSidebar = create(
  persist(
    (set) => ({
      shouldHideRightHandSide: false,
      setHideRightHandSide: (shouldHide) => {
        mainCalendarBroadcast.publish("REMOVE_PREVIEW_EVENT"); // remove all the preview events

        set((state) => ({ shouldHideRightHandSide: shouldHide }));
      },
      resetRightHandSidebar: () =>
        set((state) => ({ shouldHideRightHandSide: false })),
    }),
    {
      name: "hide-right-hand-side-storage",
    }
  )
);

export const useReferralStore = create(
  persist(
    (set) => ({
      hasShownReferBoost: false,
      isInReferBoost: false,
      stopReferBoost: () => {
        set((state) => ({
          isInReferBoost: false,
        }));
      },
      setHasShownReferBoost: () => {
        set((state) => ({
          hasShownReferBoost: true,
          isInReferBoost: true,
        }));
      },
      resetReferralStore: () => {
        set(() => ({
          hasShownReferBoost: false,
          isInReferBoost: false,
        }));
      },
    }),
    {
      name: "referral-storage",
    }
  )
);

export const useAccountActivity = create(
  persist((set) => ({
    hasReceivedLoginCode: false,
    isStillPendingInitialCalendarSync: true,
    setHasReceivedLoginCode: () => {
      set((state) => ({
        hasReceivedLoginCode: true,
      }));
    },
    onReceiveInitialSync: () => {
      set((state) => ({
        isStillPendingInitialCalendarSync: false,
        hasReceivedLoginCode: false,
      }));
    },
    resetAccountActivity: () => {
      set(() => ({
        hasReceivedLoginCode: false,
        isStillPendingInitialCalendarSync: true,
        lastClickedLoginButton: null,
      }));
    },
    lastClickedLoginButton: null, // google or outlook, use CALENDAR_PROVIDERS enum
    setLastClickedLoginButton: (lastClickedLoginButton) => {
      set((state) => ({ lastClickedLoginButton }));
    },
  }), {
    name: "account-activity-storage", // unique name of the store
    partialize: (state) => ({
      lastClickedLoginButton: state.lastClickedLoginButton,
    }), // only persist lastClickedLoginButton
  }),
);

export const useTutorialWizard = create(
  persist(
    (set) => ({
      hasRemovedTutorialWizard: false,
      isTutorialWizardShowing: false,
      isTutorialWizardMinimized: true,
      expandTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardMinimized: false,
        }));
      },
      minimizeTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardMinimized: true,
        }));
      },
      showMinifiedTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardShowing: true,
          isTutorialWizardMinimized: true,
        }));
      },
      showTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardShowing: true,
          isTutorialWizardMinimized: false,
        }));
      },
      removeTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardShowing: false,
          isTutorialWizardMinimized: true,
          hasRemovedTutorialWizard: true,
        }));
      },
      resetTutorialWizard: () => {
        set((state) => ({
          isTutorialWizardShowing: false,
          isTutorialWizardMinimized: true,
          hasRemovedTutorialWizard: false,
        }));
      },
    }),
    {
      name: "tutorial-wizard-storage",
    }
  )
);

export const useFeatureFlags = create(
  persist(
    (set) => ({
      shouldShowFindTime: false,
      setShouldShowFindTime: () => {
        set((state) => ({
          shouldShowFindTime: true,
        }));
      },
      shouldShowRewind2023: false,
      showRewind2023: () => {
        set((state) => ({
          shouldShowRewind2023: true,
        }));
      },
      hideRewind2023: () => {
        set((state) => ({
          shouldShowRewind2023: false,
        }));
      },
      resetFeatureFlags: () => {
        set((state) => ({
          shouldShowRewind2023: false,
        }));
      },
    }),
    {
      name: "flag-storage",
    }
  )
);
