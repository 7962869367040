global.$RefreshReg$ = () => {}; // eslint-disable-line no-restricted-globals
global.$RefreshSig$$ = () => () => {}; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React from "react";
import Classnames from "classnames";

function Spinner(props) {
  const getClassname = () => {
    if (props.useBigSpinner) {
      return "big-spinner";
    } else if (props.useSmallSpinner) {
      return "small-spinner";
    }
    return "spin";
  };
  return <div className={Classnames(getClassname(), props.className)}></div>;
}

export default Spinner;
