import * as reducers from "../services/reducers";
import { combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import localForage from "localforage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { PERSIST_KEY } from "../services/googleCalendarService";
import { handleError } from "../services/commonUsefulFunctions";
import { isLocal } from "../services/devFunctions";

// TODO: add middleware for both redux and persist
const combinedReducers = combineReducers(reducers);

try {
  localForage.config({
    driver: localForage.INDEXEDDB, // Force WebSQL; same as using setDriver()
    name: PERSIST_KEY,
    version: 1.0,
    storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
    description: PERSIST_KEY,
  });
} catch (e) {
  handleError(e);
}

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return combinedReducers(state, action);
};

const persistConfig = {
  key: "root",
  timeout: 0,
  storage: localForage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    "isEditModeOn",
    "isCreateAvailabilityModeOn",
    "shouldShowGlobalKeyMap",
    "currentPreviewedEvent",
    "agendaDay",
    "shouldDisplayMenu",
    "currentHoverEvent",
    "isCommandCenterOn",
    "popupEvent",
    "hoverPopupEvent",
    "isWeeklyCalendarZoomedIn",
    "temporaryEvents",
    "eventFormEmails",
    "emailToNameIndex",
    "temporarySecondaryCalendarColorsIndex",
    "secondaryCalendarColors",
    "shouldShowTopBar",
    "currentTimeZone",
    "templateSideBar",
    "selectedDay",
    "shouldShowShortcutsLegend",
    "lastEventFormState",
    "isDuplicateEvent",
    "recentTags",
    "originalRecurrenceEventIndex",
    "temporaryTimeZones",
    "currentTimeZoneLabel",
    "isCreateFocusModeBlocks",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// TODO: remove dev key below
export const store = createStore(
  persistedReducer,
  isLocal() &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
);

// export const store = createStore(persistedReducer, applyMiddleware(logger));
export const persistor = persistStore(store);

// persistor.pause();
