import * as Sentry from "@sentry/browser";

import { BACKEND_SETTINGS_NAMES } from "../lib/vimcalVariables";
import { sendBreadcrumbToSentry } from "./commonUsefulFunctions";
import { isMaestroUserOnDelegatedAccount } from "./maestroFunctions";
import { VERSION_2, getAppVersion } from "./versionFunctions";
import { getUserConnectedAccountDetails, isUserFromMagicLink } from "./maestro/maestroAccessors";
import { isEmptyObjectOrFalsey } from "./typeGuards";
import { getMasterAccountSettings } from "../lib/userFunctions";

const BACKEND_VERSION = getAppVersion();

/* Separate for each EA - Use EA's master account */
const EA_MASTER_ACCOUNT_KEYSET = [
  BACKEND_SETTINGS_NAMES.AUTO_MERGE_EVENTS,
  BACKEND_SETTINGS_NAMES.CALENDAR_VIEW,
  BACKEND_SETTINGS_NAMES.DARK_MODE,
  BACKEND_SETTINGS_NAMES.DIM_PAST_EVENTS,
  BACKEND_SETTINGS_NAMES.HIDE_OOO_COLUMN,
  BACKEND_SETTINGS_NAMES.HIDE_BUSY_COLUMN,
  BACKEND_SETTINGS_NAMES.HIDE_WEEKENDS,
  BACKEND_SETTINGS_NAMES.HIDE_WORK_LOCATION_EVENTS,
  BACKEND_SETTINGS_NAMES.MATCH_OS_SCHEME_DESKTOP,
  BACKEND_SETTINGS_NAMES.MENU_BAR_CALENDARS,
  BACKEND_SETTINGS_NAMES.MOBILE_THEME,
  BACKEND_SETTINGS_NAMES.SHOW_DECLINED_EVENTS,
  BACKEND_SETTINGS_NAMES.START_OF_WEEK,
  BACKEND_SETTINGS_NAMES.WEEK_NUMBER,
  BACKEND_SETTINGS_NAMES.WELCOME_TOUR_DISMISSED,
];

/* Separate for each EA - only while toggled onto Executive */
const CONNECTED_SETTINGS_KEYSET = [
  BACKEND_SETTINGS_NAMES.ANCHOR_TIME_ZONES,
  BACKEND_SETTINGS_NAMES.LOCAL_TIME_ZONE,
  BACKEND_SETTINGS_NAMES.MOBILE_WIDGET_SETTING,
  BACKEND_SETTINGS_NAMES.ORDERED_TIME_ZONES,
  BACKEND_SETTINGS_NAMES.RECENT_CONTACTS,
  BACKEND_SETTINGS_NAMES.TIME_ZONE_OVERRIDE,
  BACKEND_SETTINGS_NAMES.USE_TIME_ZONE_OVERRIDE,
  BACKEND_SETTINGS_NAMES.WORK_HOURS,
];

/* Shared only between EAs */
const CONNECTED_SHARED_SETTINGS_KEYSET = [
  BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_NAME,
  BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_URL,
  BACKEND_SETTINGS_NAMES.DEFAULT_CONFERENCING_OPTION,
  BACKEND_SETTINGS_NAMES.DEFAULT_PHONE_OPTION,
  BACKEND_SETTINGS_NAMES.DEFAULT_ZOOM_MODE,
];

/* Shared by exec and EA - master_account level */
const EXECUTIVE_MASTER_ACCOUNT_SETTINGS_KEYSET = [
  BACKEND_SETTINGS_NAMES.AUTO_EMOJIS,
  BACKEND_SETTINGS_NAMES.COLOR_LABELS,
  BACKEND_SETTINGS_NAMES.DATE_FORMAT,
  BACKEND_SETTINGS_NAMES.DEFAULT_MEETING_DURATION,
  BACKEND_SETTINGS_NAMES.HIDE_DEFAULT_SIGNATURE,
  BACKEND_SETTINGS_NAMES.MILITARY_TIME,
  BACKEND_SETTINGS_NAMES.TRIPS,
  BACKEND_SETTINGS_NAMES.USERNAME,
];

/* Shared by exec and EA - user level */
const EXECUTIVE_SETTINGS_KEYSET = [
  BACKEND_SETTINGS_NAMES.CONTACT_GROUPS,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_INVITE_OTHERS,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_MODIFY_EVENTS,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_SEE_GUEST_LIST,
];

function getConnectedAccountSettingValue({ backendSettingName, masterAccount, user }) {
  const connectedAccountDetails = getUserConnectedAccountDetails({ user });

  /* Should never enter this */
  if (isEmptyObjectOrFalsey(connectedAccountDetails)) {
    sendBreadcrumbToSentry({
      category: "", // What should this be?
      message: "Missing connected account details.",
      data: {
        backendSettingName,
        user,
      },
      level: Sentry.Severity.Warning,
    });

    return null;
  }

  const {
    connected_settings: connectedSettings,
    connected_shared_settings: connectedSharedSettings,
    master_account: executiveMasterAccount,
    settings,
  } = connectedAccountDetails;

  if (EA_MASTER_ACCOUNT_KEYSET.includes(backendSettingName)) {
    return getMasterAccountSettings(masterAccount)?.[backendSettingName];
  }

  if (CONNECTED_SETTINGS_KEYSET.includes(backendSettingName)) {
    return connectedSettings?.[backendSettingName];
  }

  if (CONNECTED_SHARED_SETTINGS_KEYSET.includes(backendSettingName)) {
    return connectedSharedSettings?.[backendSettingName];
  }

  if (EXECUTIVE_MASTER_ACCOUNT_SETTINGS_KEYSET.includes(backendSettingName)) {
    return executiveMasterAccount?.settings?.[backendSettingName];
  }

  if (EXECUTIVE_SETTINGS_KEYSET.includes(backendSettingName)) {
    return settings?.[backendSettingName];
  }

  /* We should never reach this */
  sendBreadcrumbToSentry({
    category: "", // What should this be?
    message: "Failed to find connected account setting.",
    data: {
      backendSettingName,
      masterAccount,
      user,
    },
    level: Sentry.Severity.Warning,
  });
  return null;
}

/* Always use master account */
const MASTER_ACCOUNT_SPECIFIC_SETTINGS = [
  BACKEND_SETTINGS_NAMES.AUTO_MERGE_EVENTS,
  BACKEND_SETTINGS_NAMES.DARK_MODE,
  BACKEND_SETTINGS_NAMES.DATE_FORMAT,
  BACKEND_SETTINGS_NAMES.DIM_PAST_EVENTS,
  BACKEND_SETTINGS_NAMES.HIDE_BUSY_COLUMN,
  BACKEND_SETTINGS_NAMES.HIDE_DEFAULT_SIGNATURE,
  BACKEND_SETTINGS_NAMES.HIDE_OOO_COLUMN,
  BACKEND_SETTINGS_NAMES.HIDE_WEEKENDS,
  BACKEND_SETTINGS_NAMES.MATCH_OS_SCHEME_DESKTOP,
  BACKEND_SETTINGS_NAMES.MENU_BAR_CALENDARS,
  BACKEND_SETTINGS_NAMES.MILITARY_TIME,
  BACKEND_SETTINGS_NAMES.MOBILE_THEME,
  BACKEND_SETTINGS_NAMES.MOBILE_WIDGET_SETTING,
  BACKEND_SETTINGS_NAMES.SHOW_DECLINED_EVENTS,
  BACKEND_SETTINGS_NAMES.START_OF_WEEK,
  BACKEND_SETTINGS_NAMES.WEEK_NUMBER,
  BACKEND_SETTINGS_NAMES.WELCOME_TOUR_DISMISSED,
  BACKEND_SETTINGS_NAMES.DEFAULT_SELF_AS_SENDER,
];

/* Swap between delegated_user and master_account */
const DELEGATED_SHARED_SETTINGS = [
  BACKEND_SETTINGS_NAMES.ANCHOR_TIME_ZONES,
  BACKEND_SETTINGS_NAMES.AUTO_EMOJIS,
  BACKEND_SETTINGS_NAMES.CALENDAR_VIEW,
  BACKEND_SETTINGS_NAMES.DEFAULT_MEETING_DURATION,
  BACKEND_SETTINGS_NAMES.LOCAL_TIME_ZONE,
  BACKEND_SETTINGS_NAMES.ORDERED_TIME_ZONES,
  BACKEND_SETTINGS_NAMES.TIME_ZONE_OVERRIDE,
  BACKEND_SETTINGS_NAMES.TRIPS,
  BACKEND_SETTINGS_NAMES.USE_TIME_ZONE_OVERRIDE,
  BACKEND_SETTINGS_NAMES.WORK_HOURS,
  BACKEND_SETTINGS_NAMES.HIDE_WORK_LOCATION_EVENTS,
  BACKEND_SETTINGS_NAMES.COLOR_LABELS,
  BACKEND_SETTINGS_NAMES.INTERNAL_DOMAINS,
];

/* Always use user */
const USER_SPECIFIC_SETTINGS = [
  BACKEND_SETTINGS_NAMES.CONTACT_GROUPS,
  BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_NAME,
  BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_URL,
  BACKEND_SETTINGS_NAMES.DEFAULT_CONFERENCING_OPTION,
  BACKEND_SETTINGS_NAMES.DEFAULT_PHONE_OPTION,
  BACKEND_SETTINGS_NAMES.DEFAULT_ZOOM_MODE,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_MODIFY_EVENTS,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_INVITE_OTHERS,
  BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_SEE_GUEST_LIST,
];

export function getSettingValue({ backendSettingName, masterAccount, user }) {
  /* Handle magic link users separately */
  if (isUserFromMagicLink({ user })) {
    return getConnectedAccountSettingValue({ backendSettingName, masterAccount, user });
  }
  
  /* Switch between master account or user depending on whether or not user is Maestro and delegation */
  if (DELEGATED_SHARED_SETTINGS.includes(backendSettingName)) {
    /* Base case - User is not Maestro or not on delegated user */
    if (!isMaestroUserOnDelegatedAccount({ masterAccount, user })) {
      return masterAccount?.settings?.[backendSettingName];
    } else {
    /* User is Maestro and on delegated account */
      return user?.settings?.[backendSettingName];
    }
  }

  if (MASTER_ACCOUNT_SPECIFIC_SETTINGS.includes(backendSettingName)) {
    return masterAccount?.settings?.[backendSettingName];
  }

  if (USER_SPECIFIC_SETTINGS.includes(backendSettingName)) {
    return user?.settings?.[backendSettingName];
  }

  /* We should never reach this */
  sendBreadcrumbToSentry({
    category: "", // What should this be?
    message: "Failed to find setting.",
    data: {
      backendSettingName,
      masterAccount,
      user,
    },
    level: Sentry.Severity.Warning,
  });
  return null;
}

export function hasUserSettings(user) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getUserSettings(user)?.length > 0;
    default:
      return getUserSettings(user)?.length > 0;
  }
}

export function getUserSettings(user) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return user?.settings?.raw_json?.items;
    default:
      return user?.settings?.raw_json?.items;
  }
}
