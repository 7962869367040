import { getCalendarUserEmail, isOutlook } from "../lib/calendarFunctions";
import { formatEmail, isSameEmail } from "../lib/stringFunctions";
import {
  VERSION_2,
  getAppVersion
} from "./versionFunctions";

const BACKEND_VERSION = getAppVersion();

export function getCalendarObject(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return calendar?.calendar ?? getCalendarObjectV1(calendar);
    default:
      return getCalendarObjectV1(calendar);
  }
}

function getCalendarObjectV1(calendar) {
  return calendar?.calendar?.raw_json;
}

export function getCalendarColorID(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.color ?? getCalendarColorIDV1(calendar);
    default:
      return getCalendarColorIDV1(calendar);
  }
}

export function getCalendarColorIDV1(calendar) {
  return getCalendarObject(calendar)?.colorId ?? getCalendarObjectV1(calendar)?.colorId;
}

export function getCalendarDefaultReminders(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.default_reminders ?? getCalendarDefaultRemindersV1(calendar);
    default:
      return getCalendarDefaultRemindersV1(calendar);
  }
}

function getCalendarDefaultRemindersV1(calendar) {
  return getCalendarObject(calendar)?.defaultReminders ?? getCalendarObjectV1(calendar)?.defaultReminders;
}

export function getCalendarNotificationSettings(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.notification_settings ?? getCalendarNotificationSettingsV1(calendar);
    default:
      return getCalendarNotificationSettingsV1(calendar);
  }
}

function getCalendarNotificationSettingsV1(calendar) {
  return getCalendarObject(calendar)?.notificationSettings ?? getCalendarObjectV1(calendar)?.notificationSettings;
}

export function getCalendarConferenceProperties(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.conference_properties ?? getCalendarConferencePropertiesV1(calendar);
    default:
      return getCalendarConferencePropertiesV1(calendar);
  }
}

function getCalendarConferencePropertiesV1(calendar) {
  return getCalendarObject(calendar)?.conferenceProperties ?? getCalendarObjectV1(calendar)?.conferenceProperties;
}

export function getCalendarIsPrimary(calendar) {
  // return getCalendarObject(calendar)?.primary;
  return calendar?.calendar?.primary;
}

export function getCalendarIsDeleted(calendar) {
  return calendar?.calendar?.deleted;
}

export function getCalendarLocation(calendar) {
  return getCalendarObject(calendar)?.location;
}

export function getCalendarIsSelected(calendar) {
  // We set this only on frontend. Not nested in second calendar.
  return calendar?.selected;
}

export function getCalendarIsHidden(calendar) {
  return calendar?.calendar?.hidden;
}

export function getCalendarEditRole(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.access_role ?? getCalendarEditRoleV1(calendar);
    default:
      return getCalendarEditRoleV1(calendar);
  }
}

function getCalendarEditRoleV1(calendar) {
  return getCalendarObject(calendar)?.accessRole ?? getCalendarObjectV1(calendar)?.accessRole;
}

export function getCalendarID(calendar) {
  return calendar?.calendar?.id;
  // switch (BACKEND_VERSION) {
  //   case VERSION_2:
  //     return getCalendarObject(calendar)?.provider_id;
  //   default:
  //     return getCalendarObject(calendar)?.id;
  // }
}

export function getCalendarBackgroundColor(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.background_color ?? getCalendarBackgroundColorV1(calendar);
    default:
      return getCalendarBackgroundColorV1(calendar);
  }
}

function getCalendarBackgroundColorV1(calendar) {
  return getCalendarObject(calendar)?.backgroundColor ?? getCalendarObjectV1(calendar)?.backgroundColor;
}

export function getCalendarForegroundColor(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.foreground_color ?? getCalendarForegroundColorV1(calendar);
    default:
      return getCalendarForegroundColorV1(calendar);
  }
}

function getCalendarForegroundColorV1(calendar) {
  return getCalendarObject(calendar)?.foregroundColor ?? getCalendarObjectV1(calendar)?.foregroundColor;
}

export function getCalendarEtag(calendar) {
  return getCalendarObject(calendar)?.etag;
}

export function getCalendarSummary(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      return getCalendarObject(calendar)?.name ?? getCalendarSummaryV1(calendar);
    default:
      return getCalendarObject(calendar)?.summary;
  }
}

export function getCalendarSummaryV1(calendar) {
  return getCalendarObject(calendar)?.summary ?? getCalendarObjectV1(calendar)?.summary;
}

export function getCalendarDescription(calendar) {
  return getCalendarObject(calendar)?.description;
}

export function getCalendarProviderId(calendar) {
  if (BACKEND_VERSION === VERSION_2) {
    const providerID = getCalendarObject(calendar)?.provider_id
      ?? getCalendarObject(calendar)?.google_id // sanity check
      ?? getCalendarEmailV1(calendar);

    if (isOutlook(calendar)) {
      // outlook provider id can be uppercase
      return providerID;
    } else {
      // should always be lower case
      return formatEmail(providerID);
    }
  }

  return getCalendarEmailV1(calendar);
}

export function getCalendarOwnerEmail(calendar) {
  if (isOutlook(calendar)) {
    return formatEmail(getCalendarObject(calendar)?.owner_email ?? "");
  }

  return getCalendarProviderId(calendar);
}

export function isMatchingCalendarEmailOrUserEmail(calendar, email) {
  return isSameEmail(getCalendarEmail(calendar), email) 
    || isSameEmail(getCalendarUserEmail(calendar), email);
}

export function getCalendarEmail(calendar) {
  switch (BACKEND_VERSION) {
    case VERSION_2:
      if (isOutlook(calendar)) {
        // We should theoretically always have userEmail since it's set on sync.
        // outlook does not return calendar email, provider id for outlook is a weird long string
        return getCalendarOwnerEmail(calendar) || calendar?.userEmail;
      }

      return getCalendarProviderId(calendar) || getCalendarEmailV1(calendar);
    default:
      return getCalendarEmailV1(calendar);
  }
}

function getCalendarEmailV1(calendar) {
  return calendar?.calendar?.google_id ?? calendar?.calendar?.provider_id; // same as .raw_json.id?
}

export function getCalendarUserCalendarID(calendar) {
  return calendar?.calendar?.user_calendar_id;
}

export function isBlockedCalendar(calendar) {
  return calendar?.calendar?.is_blocked;
}

export function addCalendarSelected({ calendar, selected }) {
  return {
    ...calendar,
    selected
  }
}

export function getCalendarColorHex(calendar) {
  return getCalendarObject(calendar)?.calendar_color;
}

export function getCalendarAllowedMeetingProviders(calendar) {
  return getCalendarObject(calendar)?.allowedOnlineMeetingProviders ?? getCalendarObjectV1(calendar)?.allowedOnlineMeetingProviders;
}

export function getCalendarDefaultMeetingProvider(calendar) {
  const defaultOnlineMeetingProvider = getCalendarObject(calendar)?.defaultOnlineMeetingProvider ?? getCalendarObjectV1(calendar)?.defaultOnlineMeetingProvider;
  if (defaultOnlineMeetingProvider === "unknown") {
    const allowed = getOutlookAllowedConferencing(calendar);
    if (allowed) {
      return allowed;
    }
  }
  return defaultOnlineMeetingProvider;
}

export function getGoogleCalendarAllowedConferenceType(calendar) {
  return getCalendarObject(calendar)?.raw_json?.conferenceProperties?.allowedConferenceSolutionTypes?.[0];
}

export function getOutlookAllowedConferencing(calendar) {
  const defaultConferencing = getCalendarObject(calendar)?.default_online_meeting_provider;
  if (!defaultConferencing || defaultConferencing === "unknown") {
    const allowedConferencing = getCalendarObject(calendar)?.allowed_online_meeting_providers?.[0];
    if (allowedConferencing) {
      return allowedConferencing;
    }
  }
  return defaultConferencing;
}

export function getCalendarLastSyncedAt(calendar) {
  return calendar?.lastSyncedAt;
}
