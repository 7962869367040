import classNames from "classnames";
import React from "react";
import Switch from "react-switch";
import { getSwitchOffColor } from "../lib/styleFunctions";
import { useSelector } from "react-redux";

interface DefaultSwitchProps {
  disabled?: boolean
  isChecked: boolean
  onChange: (checked: boolean) => void
  additionalClassNames?: string
  id?: string
  tabIndex?: number
}

export default function DefaultSwitch({
  disabled = false,
  isChecked,
  onChange,
  additionalClassNames,
  id,
  tabIndex,
}: DefaultSwitchProps) {
  const isDarkMode = useSelector((state) => state.isDarkMode);
  return (
    <Switch
      tabIndex={tabIndex}
      checked={isChecked}
      onChange={onChange}
      onColor="#86d3ff"
      offColor={getSwitchOffColor(isDarkMode)}
      onHandleColor="#2693e6"
      handleDiameter={16}
      uncheckedIcon={false}
      checkedIcon={false}
      disabled={disabled}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={12}
      width={25}
      className={classNames("react-switch", isChecked ? "" : "react-switch-hover", additionalClassNames || "")}
      id={id}
    />
  );
}
