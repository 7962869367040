import { constructRequestURL } from "../services/api";
import { getEventID } from "../services/eventResourceAccessors";
import Fetcher from "../services/fetcher";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { convertUserCalendarIDToProviderIDs } from "./calendarFunctions";
import {
  GET_LATEST_MASTER_ACCOUNT_ENDPOINT,
  GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS,
  GET_OUTLOOK_PREVIEW_EVENTS_WITHOUT_CALENDARS,
  GET_TEAM_TEMPORARY_PREVIEW_EVENTS,
  GET_TEMPORARY_PREVIEW_EVENTS,
} from "./endpoints";
import { getEventCalendarProviderID } from "./eventFunctions";
import { getUserEmail } from "./userFunctions";

/* Don't use const object since it references */
export function getDefaultHeaders() {
  return { "Accept-Encoding": "gzip" };
}

// returns {events: [], calendars: []};
// will only work for outlook
export function getOutlookPreviewEvents({
  calendarIDs,
  timeMin,
  timeMax,
  currentTimeZone,
  userEmail,
  onlySyncEvents,
  allCalendars,
}) {
  const url = constructRequestURL(
    onlySyncEvents
      ? GET_OUTLOOK_PREVIEW_EVENTS_WITHOUT_CALENDARS
      : GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS,
    true
  );

  const body = onlySyncEvents
    ? {
        timeMin,
        timeMax,
        timeZone: currentTimeZone,
        calendarIds: convertUserCalendarIDToProviderIDs({
          userCalendarIDs: calendarIDs,
          allCalendars,
        }), // get providerID
      }
    : {
        timeMin: timeMin.toISOString(),
        timeMax: timeMax.toISOString(),
        timeZone: currentTimeZone,
        selected_calendar_ids: calendarIDs,
      };
  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  };
  return Fetcher.post(url, payloadData, true, userEmail);
}

export function isValidOutlookPreviewResponse({ response, onlySyncEvents }) {
  if (isEmptyObjectOrFalsey(response)) {
    return false;
  }
  if (onlySyncEvents) {
    return !!response.events;
  }
  return !!response.calendars && !!response.events;
}

export function getFullEvent({ event, userEmail }) {
  const url = constructRequestURL(
    `calendars/${getEventCalendarProviderID(event)}/events/${getEventID(
      event
    )}/fetch`,
    true
  );
  return Fetcher.get(url, {}, true, userEmail);
}

export function fetchOutlookPreviewMeetWithEvents({
  userEmail,
  currentUser,
  startDate,
  endDate,
  emails,
  currentTimeZone,
  isTeamSlots,
}) {
  const url = constructRequestURL(
    isTeamSlots
      ? GET_TEAM_TEMPORARY_PREVIEW_EVENTS
      : GET_TEMPORARY_PREVIEW_EVENTS,
    true
  );
  const body = {
    timeMin: startDate.toISOString(),
    timeMax: endDate.toISOString(),
    timeZone: currentTimeZone,
    calendarIds: emails,
  };
  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  };
  return Fetcher.post(
    url,
    payloadData,
    true,
    userEmail ?? getUserEmail(currentUser)
  );
}

export async function fetchLatestMasterAccount({user}) {
  const url = constructRequestURL(GET_LATEST_MASTER_ACCOUNT_ENDPOINT, true);
  return Fetcher.get(url, {}, true, getUserEmail(user));
}
