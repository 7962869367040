export const CALENDAR_CATEGORIES_ENDPOINT = "calendars/categories";
export const FETCH_CALENDAR_EVENTS_ENDPOINT = "calendars/events";
export const LATEST_GROUP_VOTE_LINKS = "group_vote_links";
export const GROUP_SPREADSHEET_LINKS_ENDPOINT = "group_spreadsheet_links";
export const CONVERT_TO_EA_ACCOUNT_ENDPOINT = "users/convert_to_ea_account";
export const ONBOARDING_RESPONSES_ENDPOINT = "onboarding_responses";
export const SEND_MOBILE_SIGNP_EMAIL_ENDPOINT = "users/send_post_sign_up_email";
export const GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS = "preview_events_sync";
export const GET_OUTLOOK_PREVIEW_EVENTS_WITHOUT_CALENDARS = "calendars/preview_events";
export const GET_MEET_WITH_EVENTS_END_POINT = "calendars/fetch_temporary_events";
export const GET_TEAM_TEMPORARY_EVENTS = "calendars/fetch_team_temporary_events";
export const GET_TEAM_TEMPORARY_PREVIEW_EVENTS = "calendars/fetch_team_temporary_preview_events";
export const GET_TEMPORARY_PREVIEW_EVENTS = "calendars/fetch_temporary_preview_events";
export const GET_CALENDAR_ONLY_ENDPOINT = "calendars_list_sync"; // GET
export const ZOOM_MEETING_BY_ZOOM_ID = "zoom_meetings/by_zoom_id";
export const GET_MULTIPLE_CALENDAR_ONLY_ENDPOINT = "calendars_list_sync_by_tokens"; // GET
export const ZOOM_LINK_ENDPOINT = "zoom_link";
export const GET_ROOM_AVAILABILITY = "rooms/available";
export const TEAM_PLAN_PROFILE = "team_plan/profile";
export const GET_LATEST_MASTER_ACCOUNT_ENDPOINT = "users/master_account";
export const UPDATE_SLOTS_SETTINGS_ENDPOINT = "slots/settings";

export const ENRICH_CONTACTS_SOCIAL_LINKS_ENDPOINT = "contacts/enriched"; // this enriches the contact email with urls of social profiles

// v2 distro list endpoints
export const GET_USER_HAS_DISTRO_LIST_PERMISSION = "google_resources/groups/has_read_permission"; // get
export const IMPORT_DISTRO_LIST_GROUPS = "google_resources/groups/import_groups" // post and async so response is null
export const LIST_DISTRO_GROUPS = "google_resources/groups/list" // get

export const SSO_SAML_ENDPOINTS = {
  CHECK_IS_ENABLED: "sso/check_domain", // GET -> pass in {domain}. Response is {is_enabled: boolean }
  CREATE_IDENTIFY_PROVIDER_CONFIG: "sso/saml_account", // POST  -> pass in {saml_account_id (okta, saml1, etc)}
  GET_SSO_CONFIG: "sso/saml/idp_configuration",
  SSO_AUTHENTICATION: "users/sso_authentication",
  REDIRECT: "sso",
};

const MAGIC_LINK_BASE_ENDPOINT = "magic_links";
export const MAGIC_LINK_ENDPOINTS = {
  CREATE_MAGIC_LINK: MAGIC_LINK_BASE_ENDPOINT, // POST
  GET_MAGIC_LINK: MAGIC_LINK_BASE_ENDPOINT, // GET
  CONNECT_MAGIC_LINK_ACCOUNT: `${MAGIC_LINK_BASE_ENDPOINT}/connect_accounts`, // POST
};

const TEAM_PLAN_BASE_ENDPOINT = "team_plan";
export const TEAM_PLAN_ENDPOINTS = {
  CHECK_EMAIL: `${TEAM_PLAN_BASE_ENDPOINT}/check_email`, // POST
  INVITE_MEMBERS: `${TEAM_PLAN_BASE_ENDPOINT}/invite_members`, // POST
  PREVIEW_INVITE_COST: `${TEAM_PLAN_BASE_ENDPOINT}/preview_invite_cost`, // POST
};
