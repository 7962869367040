/* Fully completed tooltips array */
/* [COMMAND_CENTER, FLASH_HOTKEYS, TIME_TRAVEL, PERSONAL_LINKS, CONTACT_GROUPS, JOIN_MEETING, NLP, SLOTS] */

export const tooltipKeys = {
  COMMAND_CENTER: "COMMAND_CENTER",
  FLASH_HOTKEYS: "FLASH_HOTKEYS",
  TIME_TRAVEL: "TIME_TRAVEL",
  PERSONAL_LINKS: "PERSONAL_LINKS",
  CONTACT_GROUPS: "CONTACT_GROUPS",
  JOIN_MEETING: "JOIN_MEETING",
  NLP: "NLP",
  SLOTS: "SLOTS",
  FOCUS_MODE: "FOCUS_MODE",
  SLOTS_TUTORIAL: "SLOTS_TUTORIAL",
};

/* Fields: description: string, name: string, shortcutKeys: [string], key: string */
export const dropdownTips = [
  {
    description:
      "This is the central hub of Vimcal. You can access all features and perform quick actions without using your mouse.",
    name: "Command Center",
    shortcutKeys: ["determineCmdKey", "K"],
    key: tooltipKeys.COMMAND_CENTER,
  },
  {
    description:
      "Flash all the shortcuts on the current page to go even faster. Learn them at your own pace.",
    name: "Flash Hotkeys",
    shortcutKeys: ["F"],
    key: tooltipKeys.FLASH_HOTKEYS,
  },
  {
    description:
      "Search any city in the world to compare its time zone to yours. We’ll also show you overlapping work hours!",
    name: "Time Travel",
    shortcutKeys: ["Z"],
    key: tooltipKeys.TIME_TRAVEL,
  },
  {
    description:
      "Create permanent booking links to send or add to your email signature.",
    name: "Personal Links",
    shortcutKeys: ["Shift", "Y"],
    key: tooltipKeys.PERSONAL_LINKS,
  },
  {
    description:
      "Focus Mode is a beautiful, full-page overlay of Vimcal designed to help you jump into deep work and stay there.",
    name: "Focus Mode",
    shortcutKeys: ["0"],
    key: tooltipKeys.FOCUS_MODE,
  }
];
