/**
 * The hex values of the colors were grabbed from the Mac desktop app.
 * Each Outlook client has a separate color palette, so there isn't a definitive list of hexes.
 * The names of the colors are from the Graph documentation.
 *
 * The docs include None as a preset color option, however this only seems to be supported in the
 * Windows Outlook client (a color must be selected in the OWA, Mac client, and Android client).
 * @see https://learn.microsoft.com/en-us/graph/api/resources/outlookcategory?view=graph-rest-1.0
 */
export const OUTLOOK_COLORS: Partial<Record<string, { hex: string, name: string }>> = {
  preset0: {
    hex: "#D79E9C",
    name: "Red",
  },
  preset1: {
    hex: "#EAB076",
    name: "Orange",
  },
  preset2: {
    hex: "#EAD385",
    name: "Brown",
  },
  preset3: {
    hex: "#F7F188",
    name: "Yellow",
  },
  preset4: {
    hex: "#9CD189",
    name: "Green",
  },
  preset5: {
    hex: "#96CFBF",
    name: "Teal",
  },
  preset6: {
    hex: "#BBC5A2",
    name: "Olive",
  },
  preset7: {
    hex: "#8DAAE1",
    name: "Blue",
  },
  preset8: {
    hex: "#A192D4",
    name: "Purple",
  },
  preset9: {
    hex: "#CFA2B7",
    name: "Cranberry",
  },
  preset10: {
    hex: "#CBCACD",
    name: "Steel",
  },
  preset11: {
    hex: "#68748D",
    name: "Dark Steel",
  },
  preset12: {
    hex: "#B1B1B1",
    name: "Gray",
  },
  preset13: {
    hex: "#686868",
    name: "Dark Gray",
  },
  preset14: {
    hex: "#4E4E4E",
    name: "Black",
  },
  preset15: {
    hex: "#A22D24",
    name: "Dark Red",
  },
  preset16: {
    hex: "#C96D27",
    name: "Dark Orange",
  },
  preset17: {
    hex: "#B59339",
    name: "Dark Brown",
  },
  preset18: {
    hex: "#ADA834",
    name: "Dark Yellow",
  },
  preset19: {
    hex: "#40792E",
    name: "Dark Green",
  },
  preset20: {
    hex: "#478872",
    name: "Dark Teal",
  },
  preset21: {
    hex: "#758443",
    name: "Dark Olive",
  },
  preset22: {
    hex: "#2E5694",
    name: "Dark Blue",
  },
  preset23: {
    hex: "#594497",
    name: "Dark Purple",
  },
  preset24: {
    hex: "#854A68",
    name: "Dark Cranberry",
  },
};

