import { handleError } from "../services/commonUsefulFunctions";
import db from "../services/db";
import { getEventUserEmail } from "../services/eventResourceAccessors";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";
import { isEmptyArray } from "./arrayFunctions";
import { getObjectEmail } from "./objectFunctions";
import { getHumanAttendees } from "./eventFunctions";
import { formatEmail } from "./stringFunctions";

export async function getMatchingDistroListFromEvent(event) {
  if (!event) {
    return {};
  }
  const attendees = getHumanAttendees(event);
  const emails = attendees.map((attendee) => getObjectEmail(attendee));
  return await getMatchingDistroListFromEmails({
    emails,
    userEmail: getEventUserEmail(event),
  });
}

function createDistroListDBObject(obj) {
  if (isEmptyObjectOrFalsey(obj) || !obj.google_id || !obj.email) {
    return;
  }
  const {
    google_id: googleID,
    email,
    name,
    description,
    aliases,
    non_editable_aliases: nonEditableAliases,
    google_group_members: googleGroupMembers,
    direct_members_count: directMembersCount,
  } = obj;
  return {
    googleID,
    email: formatEmail(email),
    name,
    description,
    aliases,
    nonEditableAliases,
    googleGroupMembers,
    directMembersCount,
  };
}

export async function storeDistroLists({ userEmail, groups }) {
  if (isEmptyArray(groups) || !userEmail) {
    return;
  }

  try {
    let bulkList = [];
    groups.forEach((obj) => {
      const listObj = createDistroListDBObject(obj);
      if (listObj) {
        bulkList = bulkList.concat(listObj);
      }
    });
    return db.fetch(userEmail).groups.bulkPut(bulkList);
  } catch (error) {
    handleError(error);
  }
}

/**
 * @param {Object} options
 * @param {string} options.string - email string for search
 * @param {string} options.userEmail - the db we want to search from
 */
export async function getMatchingDistroListFromString({ string, userEmail }) {
  if (!string || !userEmail) {
    return [];
  }
  try {
    return await db
      .fetch(userEmail)
      .groups.where("email")
      .startsWithAnyOfIgnoreCase(string)
      .or("name")
      .startsWithAnyOfIgnoreCase(string)
      .or("description")
      .startsWithAnyOfIgnoreCase(string)
      .toArray();
  } catch (error) {
    handleError(error);
    return [];
  }
}

export async function getMatchingDistroListFromEmails({ emails, userEmail }) {
  if (isEmptyArray(emails) || !userEmail) {
    return {};
  }
  const dbResponse = await db
    .fetch(userEmail)
    .groups.where("email")
    .anyOf(emails)
    .toArray();
  if (isEmptyArray(dbResponse)) {
    return {};
  }
  const dictionary = dbResponse.reduce((acc, item) => {
    acc[getObjectEmail(item)] = item;
    return acc;
  }, {});
  return dictionary;
}

function getMatchingDistroList({ email, distroListDictionary }) {
  if (!distroListDictionary || !email) {
    return;
  }
  return distroListDictionary[email];
}

/**
 * google_groups will now have a property called direct_members_count
 * If this is bigger than 50 we are not returning the members on the google_members property
 */
export function getDistroDirectMembersCount({ email, distroListDictionary }) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  return matchingList?.directMembersCount ?? 0;
}

export function isDistroListEmail({ email, distroListDictionary }) {
  return !!getMatchingDistroList({ email, distroListDictionary });
}

const MEMBER_STATUS = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
};
export function getDistroListGroupMembers({ email, distroListDictionary }) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  const members = matchingList?.googleGroupMembers ?? [];
  return members.filter(
    (member) =>
      member?.status !== MEMBER_STATUS.SUSPENDED && getObjectEmail(member)
  );
}

export function getDistroListName({ email, distroListDictionary }) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  return matchingList?.name ?? "";
}

export function getDistroListDescription({ email, distroListDictionary }) {
  const matchingList = getMatchingDistroList({ email, distroListDictionary });
  return matchingList?.description;
}

export function isDistroGroupTooBigForExapansion({
  email,
  distroListDictionary,
}) {
  const LIMIT = 50;
  return (
    getDistroDirectMembersCount({ email, distroListDictionary }) >= LIMIT ||
    getDistroListGroupMembers({ email, distroListDictionary })?.length >= LIMIT
  );
}
