import React from 'react';
import { Video }                    from 'react-feather';
import {
  BACKEND_HANGOUT,
  BACKEND_NO_CONFERENCING,
  BACKEND_PHONE,
  BACKEND_ZOOM,
  GOOGLE_HANGOUT_STRING,
  NO_CONFERENCE_STRING,
  PHONE_NUMBER_CONFERENCE,
  ZOOM_STRING,
  ZOOM_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION,
  backendConferenceIconUrl,
  WHATS_APP_CONFERENCING_OPTION,
  BACKEND_WHATS_APP,
  BACKEND_CUSTOM_CONFERENCING,
  WHATS_APP_STRING
}                                   from './googleCalendarService';
import {
  createCustomConferencingOption, 
  isPhoneConferencingWhatsApp
}                                   from '../lib/conferencing';
import { OUTLOOK_CONFERENCING } from '../resources/outlookVariables';
import { SKYPE_FOR_BUSINESS_LABEL, SKYPE_FOR_CONSUMER_LABEL, TEAMS_FOR_BUSINESS_LABEL, convertOutlookConferencingToHumanReadable } from '../lib/outlookFunctions';
import { getCustomConferencingName } from '../lib/settingsFunctions';
import { truncateString } from "../lib/stringFunctions";


const renderIcon = (iconURL) => (
  <img
    alt=""
    width="16px"
    height="16px"
    src={iconURL}
  />
);

export const renderConferencingIcon = (conferencingBackend) => {
  const conferenceIconUrl = backendConferenceIconUrl(conferencingBackend);
  if (conferenceIconUrl) {
    return renderIcon(conferenceIconUrl);
  } else {
    return <Video className="non-clickable-icon" size="14" />;
  }
};

const CONFERENCING_STRING_LENGTH = 100;

export const truncateConferenceUrl = (conferenceUrl) => (
  truncateString(conferenceUrl, CONFERENCING_STRING_LENGTH)
);

export const conferencingOptions = [
  ZOOM_CONFERENCING_OPTION,
  HANGOUT_CONFERENCING_OPTION,
  PHONE_CONFERENCING_OPTION,
  NO_CONFERENCING_OPTION
];

export const conferenceOptionFromBackend = (backendConference, currentUser) => {
  switch (backendConference) {
    case BACKEND_ZOOM:
      return ZOOM_CONFERENCING_OPTION;
    case BACKEND_HANGOUT:
      return HANGOUT_CONFERENCING_OPTION;
    case BACKEND_NO_CONFERENCING:
      return NO_CONFERENCING_OPTION;
    case BACKEND_PHONE:
      return PHONE_CONFERENCING_OPTION;
    case BACKEND_WHATS_APP:
      return WHATS_APP_CONFERENCING_OPTION;
    case BACKEND_CUSTOM_CONFERENCING:
      return createCustomConferencingOption(currentUser);
    case OUTLOOK_CONFERENCING.teamsForBusiness:
      return {value: OUTLOOK_CONFERENCING.teamsForBusiness, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.teamsForBusiness)};
    case OUTLOOK_CONFERENCING.skypeForBusiness:
      return {value: OUTLOOK_CONFERENCING.skypeForBusiness, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.skypeForBusiness)};
    case OUTLOOK_CONFERENCING.skypeForConsumer:
      return {value: OUTLOOK_CONFERENCING.skypeForConsumer, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.skypeForConsumer)};
    default:
      return NO_CONFERENCING_OPTION;
  }
}

export const eventFormConferenceOptionFromBackend = (backendConference, currentUser) => {
  switch (backendConference) {
    case BACKEND_ZOOM:
      return {value: ZOOM_STRING, label: ZOOM_STRING};
    case BACKEND_HANGOUT:
      return {value: GOOGLE_HANGOUT_STRING, label: GOOGLE_HANGOUT_STRING};
    case BACKEND_NO_CONFERENCING:
      return {value: NO_CONFERENCE_STRING, label: NO_CONFERENCE_STRING};
    case BACKEND_PHONE:
      if (isPhoneConferencingWhatsApp(currentUser)) {
        return {value: WHATS_APP_STRING, label: WHATS_APP_STRING};
      }

      return {value: PHONE_NUMBER_CONFERENCE, label: PHONE_NUMBER_CONFERENCE};
    case BACKEND_CUSTOM_CONFERENCING:
      return {value: getCustomConferencingName({ user: currentUser}), label: getCustomConferencingName({ user: currentUser})};
    case OUTLOOK_CONFERENCING.teamsForBusiness:
      return {value: OUTLOOK_CONFERENCING.teamsForBusiness, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.teamsForBusiness)};
    case OUTLOOK_CONFERENCING.skypeForBusiness:
      return {value: OUTLOOK_CONFERENCING.skypeForBusiness, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.skypeForBusiness)};
    case OUTLOOK_CONFERENCING.skypeForConsumer:
      return {value: OUTLOOK_CONFERENCING.skypeForConsumer, label: convertOutlookConferencingToHumanReadable(OUTLOOK_CONFERENCING.skypeForConsumer)};
    default:
      return {value: NO_CONFERENCE_STRING, label: NO_CONFERENCE_STRING};
  }
}

export const backendConferenceValueFromHumanReadable = (humanReadableConference, currentUser) => {
  switch (humanReadableConference) {
    case ZOOM_STRING:
      return BACKEND_ZOOM;
    case GOOGLE_HANGOUT_STRING:
      return BACKEND_HANGOUT;
    case NO_CONFERENCE_STRING:
      return BACKEND_NO_CONFERENCING;
    case WHATS_APP_STRING: // Fallthrough
    case PHONE_NUMBER_CONFERENCE:
      return BACKEND_PHONE;
    case getCustomConferencingName({ user: currentUser}):
      return BACKEND_CUSTOM_CONFERENCING;
    case TEAMS_FOR_BUSINESS_LABEL:
      return OUTLOOK_CONFERENCING.teamsForBusiness;
    case SKYPE_FOR_BUSINESS_LABEL:
      return OUTLOOK_CONFERENCING.skypeForBusiness;
    case SKYPE_FOR_CONSUMER_LABEL:
      return OUTLOOK_CONFERENCING.skypeForConsumer;
    default:
      return BACKEND_NO_CONFERENCING;
  }
};
