import { getOutlookEventShowAs } from "../services/eventResourceAccessors";

export const OUTLOOK_CALENDAR_COLORS = [
  { colorId: "lightBlue", color: "#a6d1f5", label: "Light Blue" },
  { colorId: "lightGreen", color: "#87d28e", label: "Light Green" },
  { colorId: "lightOrange", color: "#fcab73", label: "Light Orange" },
  { colorId: "lightGray", color: "#c0c0c0", label: "Light Gray" },
  { colorId: "lightYellow", color: "#f4d07a", label: "Light Yellow" },
  { colorId: "lightTeal", color: "#4adacc", label: "Light Teal" },
  { colorId: "lightPink", color: "#f08cc0", label: "Light Pink" },
  { colorId: "lightBrown", color: "#cba287", label: "Light Brown" },
  { colorId: "lightRed", color: "#f88c9b", label: "Light Red" },
];

export const OUTLOOK_CONFERENCING = {
  teamsForBusiness: "teamsForBusiness",
  skypeForBusiness: "skypeForBusiness",
  skypeForConsumer: "skypeForConsumer",
};

/* The default scopes on backend */
export const OUTLOOK_DEFAULT_SCOPES = [
  "Calendars.ReadWrite",
  "Contacts.Read",
  "User.Read",
  "Calendars.ReadWrite.Shared",
  "User.ReadBasic.All",
];

/* Additional scopes */
/* Separate for backwards compatibility */
/* These are scopes that were added after the above */
export const OUTLOOK_ADDITIONAL_SCOPES = ["Mail.Send", "Mail.ReadWrite"];

// https://learn.microsoft.com/en-us/graph/api/resources/responsestatus?view=graph-rest-1.0
// Possible values are: none, organizer, tentativelyAccepted, accepted, declined, notResponded.
// Clients can treat notResponded == none.
const OUTLOOK_RESPONSE_STATUS = {
  NONE: "none",
  ORGANIZER: "organizer",
  TENTATIVELY_ACCEPTED: "tentativelyAccepted",
  ACCEPTED: "accepted",
  DECLINED: "declined",
  NOT_RESPONDED: "notResponded",
};

// https://learn.microsoft.com/en-us/graph/api/resources/event?view=graph-rest-1.0
// The status to show. Possible values are: free, tentative, busy, oof, workingElsewhere, unknown.
export const OUTLOOK_SHOW_AS = {
  FREE: "free",
  TENTATIVE: "tentative",
  BUSY: "busy",
  OOF: "oof",
  WORKING_ELSEWHERE: "workingElsewhere",
  UNKNOWN: "unknown",
};

export function isOutlookShowAsTentativeEvent(event) {
  const showAs = getOutlookEventShowAs(event);
  if (!showAs) {
    return false;
  }
  return showAs === OUTLOOK_SHOW_AS.TENTATIVE;
}
