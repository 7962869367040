import { ENVIRONMENTS } from "./vimcalVariables";

export function determineBookingLink() {
  const environment = process.env.REACT_APP_CLIENT_ENV;
  const bookingUrl = process.env.REACT_APP_BOOKING_URL;

  if (bookingUrl) {
    return bookingUrl;
  }

  switch (environment) {
    case ENVIRONMENTS.DEV:
      return "https://book-dev.vimcal.com";
    case ENVIRONMENTS.STAGING:
      return "https://book-staging.vimcal.com";
    case ENVIRONMENTS.DOGFOOD:
      // Using prod instead of dogfood because users will be sending these out
      return "https://book.vimcal.com";
    case ENVIRONMENTS.PRODUCTION:
      return "https://book.vimcal.com";
    case ENVIRONMENTS.TESTING:
      return "https://book-testing.vimcal.com";
    default:
      return "https://book.vimcal.com";
  }
}

export function getHomeLink() {
  const DEV = "https://calendar-dev.vimcal.com";
  const DOGFOOD = "https://calendar-dogfood.vimcal.com";
  const LOCAL = "http://localhost:3000";
  const STAGING = "https://calendar-staging.vimcal.com";
  const PRODUCTION = "https://calendar.vimcal.com";
  const TESTING = "https://calendar-testing.vimcal.com";

  const environment = process.env.REACT_APP_CLIENT_ENV;

  switch (environment) {
    case ENVIRONMENTS.DEV:
      return DEV;
    case ENVIRONMENTS.DOGFOOD:
      return DOGFOOD;
    case ENVIRONMENTS.LOCAL:
      return LOCAL;
    case ENVIRONMENTS.PRODUCTION:
      return PRODUCTION;
    case ENVIRONMENTS.STAGING:
      return STAGING;
    case ENVIRONMENTS.TESTING:
      return TESTING;
    default:
      return LOCAL;
  }
}

export function hasAppBaseURL() {
  return !!process.env.REACT_APP_SERVER_BASE_URL;
}

export function getAppBaseURL() {
  return process.env.REACT_APP_SERVER_BASE_URL;
}
