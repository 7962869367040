import { constructRequestURL } from "../services/api";
import { isValidTimeZone } from "../services/commonUsefulFunctions";
import Fetcher from "../services/fetcher";
import { isVersionV2 } from "../services/versionFunctions";
import { isEmptyArray } from "./arrayFunctions";
import { GET_MEET_WITH_EVENTS_END_POINT, GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS } from "./endpoints";
import {
  getDefaultEndDateTime,
  getDefaultStartDateTime,
  isBusyEvent,
} from "./eventFunctions";
import { shouldGetOutlookPreviewEvents } from "./featureFlagFunctions";
import { getDefaultHeaders } from "./fetchFunctions";
import { getUserEmail } from "./userFunctions";

export async function getMeetWithEvents({
  user,
  currentUser,
  startDate,
  endDate,
  emails,
  currentTimeZone,
}) {
  if (isEmptyArray(emails)) {
    return;
  }
  const backendUser = user ?? currentUser;
  const path = shouldGetOutlookPreviewEvents(backendUser)
    ? GET_OUTLOOK_PREVIEW_EVENTS_AND_CALENDARS
    : GET_MEET_WITH_EVENTS_END_POINT;
  const url = constructRequestURL(path, isVersionV2());
  const body = {
    timeMin: startDate.toISOString(),
    timeMax: endDate.toISOString(),
    timeZone: currentTimeZone,
    calendarIds: emails,
  };
  const payloadData = {
    headers: getDefaultHeaders(),
    body: JSON.stringify(body),
  };
  return Fetcher.post(url, payloadData, true, getUserEmail(backendUser));
}

// response comes in as {calendars
// [
// 	{john@vimcal.com: {
// 		items: [events]
// 	}}
// ]}
export function extractEventsFromMeetWithResponse(response) {
  if (isEmptyArray(response?.calendars)) {
    return [];
  }
  let unformattedEvents = [];
  const { calendars } = response;
  calendars.forEach((calendar) => {
    Object.keys(calendar).forEach((calendarEmail) => {
      if (!isEmptyArray(calendar[calendarEmail]?.items)) {
        const calendarEvents = calendar[calendarEmail].items;
        unformattedEvents = unformattedEvents.concat(calendarEvents);
      }
    });
  });
  return unformattedEvents;
}

// return {start, end} which match free busy responses
export function extractBusyTimesFromMeetWithResponse(response) {
  const extractedEvents = extractEventsFromMeetWithResponse(response);
  if (isEmptyArray(extractedEvents)) {
    return [];
  }
  return extractedEvents
    .filter((event) => isBusyEvent(event))
    .map((event) => {
      return {
        start: getDefaultStartDateTime(event),
        end: getDefaultEndDateTime(event),
      };
    })
    .filter((times) => times.start && times.end);
}

export function doesArrayContainMeetWithEvents(arr) {
  if (isEmptyArray(arr)) {
    return false;
  }
  return arr.some((event) => isMeetWithEvent(event));
}

export function isMeetWithEvent(event) {
  return event?.isMeetWithEvent;
}

export function getTimeZoneFromMeetWithItem({key, item}) {
  const timeZone = item?.[key]?.timeZone;
  if (!isValidTimeZone(timeZone)) {
    return null;
  }
  return timeZone;
}
