import create from "zustand";
import { persist } from "zustand/middleware";

export const usePermissionsStore = create(
  persist(
    (set) => ({
      enrichCompaniesPermission: true,
      setEnrichCompaniesPermission: (enrichCompaniesPermission) => {
        set((state) => ({ enrichCompaniesPermission }));
      },
      reverseSlotsPermission: false,
      setReverseSlotspermission: (reverseSlotsPermission) => {
        set((state) => ({ reverseSlotsPermission }));
      },
      hasSeenFTFModal: false,
      setHasSeenFTFModal: (hasSeenFTFModal) => {
        set((state) => ({ hasSeenFTFModal }));
      },
      hasSelfServeAccess: true,
      setHasSelfServeAccess: (hasSelfServeAccess) => {
        // set((state) => ({ hasSelfServeAccess }));
      },
      hideFirstPageOfOnboarding: false,
      setHideFirstPageOfOnboarding: (hideFirstPageOfOnboarding) => {
        set((state) => ({ hideFirstPageOfOnboarding }));
      },
      resetPermissions: () =>
        set((state) => ({
          reverseSlotsPermission: false,
          enrichCompaniesPermission: true,
          hasSeenFTFModal: false,
          hasSelfServeAccess: true,
          hideFirstPageOfOnboarding: false
        })),
    }),
    {
      name: "permissions-store",
    }
  )
);
