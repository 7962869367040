export const COFFEE_MUSIC =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/CafeVibes.mp3";
export const RAIN_MUSIC =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/RainVibes.mp3";
export const BEACH_MUSIC =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/BeachVibes.mp3";
export const SNOW_MUSIC = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/snow-walk.mp3";
export const FIRE_PLACE_MUSIC = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/FireVibes.mp3";
export const JAZZ_MUSIC = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/4K+Cozy+Bedroom+in+Paris+with+Relaxing+Piano+Jazz+Music+for+Sleeping+Studying+%5BTubeRipper.com%5D-%5BAudioTrimmer.com%5D.mp3";
export const LOFI_MUSIC = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/1+A.M+Study+Session+-+%5Blofi+hip+hopchill+beats%5D+%5BTubeRipper.com%5D-%5BAudioTrimmer.com%5D.mp3";
export const TIMER_COMPLETE =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/mixkit-positive-notification-951.wav";
export const ALL_FOCUS_MODE_SOUND_TRACKS = [
  COFFEE_MUSIC,
  RAIN_MUSIC,
  BEACH_MUSIC,
  TIMER_COMPLETE,
  JAZZ_MUSIC,
  LOFI_MUSIC,
  FIRE_PLACE_MUSIC,
  SNOW_MUSIC
];

export const TIMER_IS_PAUSED_ID = "timer-paused";
export const TIMER_IS_COUNTING_DOWN_ID = "timer-count-down";
export const TIMER_IS_NOT_COUNTING_DOWN_ID = "timer";
export const FOCUS_MODE_SOUND_ON = "sound-on";
export const FOCUS_MODE_SOUND_OFF = "sound-off";

// images
export const DEFAULT_POMODORO_BACKGROUND =
  "https://vimcal-app-images.s3.amazonaws.com/garrett-parker-DlkF4-dbCOU-unsplash.jpg";
const IMAGE_1 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/alexander-schimmeck-GZ9U7ySRCHI-unsplash.jpg";
const IMAGE_2 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/andreas-m-GQ5aE4wdkLU-unsplash.jpg";
const IMAGE_3 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/wil-stewart-pHANr-CpbYM-unsplash.jpg";
const IMAGE_4 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/artem-kniaz-e3K4OOI3DcI-unsplash.jpg";
const IMAGE_5 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/deglee-ganzorig-TIHZhnpKP0g-unsplash.jpg";
const IMAGE_6 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/fred-russo-3Q83dROp3Fk-unsplash.jpg";
const IMAGE_7 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/gaetano-cessati-CCy2UFLO1Mg-unsplash.jpg";
const IMAGE_8 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/hans-isaacson-bQTVoJHrkO0-unsplash.jpg";
export const IMAGE_9 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/andreas-m-Uy6PW2OdWUg-unsplash.jpg";
const IMAGE_10 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/james-donovan-kFHz9Xh3PPU-unsplash.jpg";
const IMAGE_11 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/johannes-plenio-RwHv7LgeC7s-unsplash.jpg";
const IMAGE_12 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/joshua-woroniecki-GW7AiVXuuuY-unsplash.jpg";
const IMAGE_13 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/joshua-woroniecki-UNSs8TXxQ1c-unsplash.jpg";
const IMAGE_14 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/luca-bravo-A-fubu9QJxE-unsplash.jpg";
const IMAGE_15 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/marin-tom-LZhMTnrZxSU-unsplash.jpg";
const IMAGE_16 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/mario-dobelmann-jYczcEx1694-unsplash.jpg";
const IMAGE_17 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/markus-winkler-CqBggP5SA10-unsplash.jpg";
const IMAGE_18 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/paul-szewczyk-yHmnjcVHoP4-unsplash.jpg";
const IMAGE_19 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/photoholgic-UPY_iepQt5c-unsplash.jpg";
const IMAGE_20 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/sean-oulashin-KMn4VEeEPR8-unsplash.jpg";
const IMAGE_21 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/daniel-roe-lpjb_UMOyx8-unsplash.jpg";
const IMAGE_22 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/sebastian-staines-O5rFo-cJu94-unsplash.jpg";
const IMAGE_23 =
  "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/simon-berger-CTwQs8QKkE8-unsplash.jpg";
const IMAGE_24 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/dave-hoefler-lsoogGC_5dg-unsplash.jpg";
const IMAGE_25 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/dawid-zawila--G3rw6Y02D0-unsplash.jpg";
const IMAGE_26 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/v2osk-1Z2niiBPg5A-unsplash.jpg";
export const IMAGE_27 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/benjamin-voros-phIFdC6lA4E-unsplash.jpg";
const IMAGE_28 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/alessio-soggetti-gdE-5Oui1Y0-unsplash.jpg";
const IMAGE_29 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/adam-kool-ndN00KmbJ1c-unsplash.jpg";
const IMAGE_30 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/bailey-zindel-NRQV-hBF10M-unsplash.jpg";
const IMAGE_31 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/joel-vodell-TApAkERW5pQ-unsplash.jpg";
const IMAGE_32 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/kalen-emsley-Bkci_8qcdvQ-unsplash.jpg";
const IMAGE_33 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/luca-bravo-VowIFDxogG4-unsplash.jpg";
const IMAGE_34 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/robby-mccullough-_rLDh9IFHf8-unsplash.jpg";
const IMAGE_35 = "https://vimcal-app-images.s3.amazonaws.com/focus-mode-images/simon-berger-twukN12EN7c-unsplash.jpg";

// images
export const BACKGROUND_IMAGES = {
  0: DEFAULT_POMODORO_BACKGROUND,
  1: IMAGE_1,
  2: IMAGE_2,
  3: IMAGE_3,
  4: IMAGE_4,
  5: IMAGE_5,
  6: IMAGE_6,
  7: IMAGE_7,
  8: IMAGE_8,
  9: IMAGE_9,
  10: IMAGE_10,
  11: IMAGE_11,
  12: IMAGE_12,
  13: IMAGE_13,
  14: IMAGE_14,
  15: IMAGE_15,
  16: IMAGE_16,
  17: IMAGE_17,
  18: IMAGE_18,
  19: IMAGE_19,
  20: IMAGE_20,
  21: IMAGE_21,
  22: IMAGE_22,
  23: IMAGE_23,
  24: IMAGE_24,
  25: IMAGE_25,
  26: IMAGE_26,
  27: IMAGE_27,
  28: IMAGE_28,
  29: IMAGE_29,
  30: IMAGE_30,
  31: IMAGE_31,
};

export const ALL_FOCUS_MODE_IMAGES = Object.values(BACKGROUND_IMAGES);
