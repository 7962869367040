export const UNAUTHORIZED_PAYMENT_ROUTE = "subscribe";
export const TYPEFORM_ROUTE = "thanks";
export const ONBOARDING_CONFIRMATION = "confirmed-booking";
export const MOBILE_DOWNLOAD_PATH = "mobile-download";
export const HOME_PATH ="home";
export const LOGIN_PATH ="login";
export const SSO_LOGIN = "sso-login";
export const SSO_MAGIC_LINK_LOGIN = "sso-magic-link-login";
export const SSO_DESKTOP_LOGIN = "sso-desktop-login";
export const SSO_NEW_USER_LOGIN = "sso-new-user-login";
export const SSO_NEW_USER_DESKTOP_LOGIN = "sso-new-user-desktop-login";
export const VIMCALEA_DEMO_PATH = "vimcalea-demo";

export function isUnauthorizedPaymentRoute() {
  return window?.location?.href?.includes(UNAUTHORIZED_PAYMENT_ROUTE);
}

export function isSSOPath() {
  return window?.location.pathname?.toLowerCase().includes("sso-") || false;
}
