// User Types
export const MAESTRO_ACCOUNT_TYPES = {
  MYSELF: "myself",
  SCHEDULER_FOR_OTHERS: "schedulerForOthers",
};

// Delegation Types
export const MAESTRO_DELEGATION_TYPES = {
  FULL: "full_access",
  LIMITED: "limited_access",
};

/* Pages for adding additional users when scheduling_for_others is true */
export const MAESTRO_NEW_USER_PAGES = {
  CARD_SELECT_ONE: "CARD_SELECT_ONE", // Card select page one (Myself, Someone else)
  CARD_SELECT_TWO: "CARD_SELECT_TWO", // Card select page two (Magic Link, Convert Calendar / Email)
  CONVERT_USER_FORM: "CONVERT_USER_FORM", // Convert user form
  MAGIC_LINK_FORM: "MAGIC_LINK_FORM", // Magic link form
  MAGIC_LINK_CREATED: "MAGIC_LINK_CREATED", // Magic link created -> copy link / create more
};

export const HOLD_TYPES = {
  AVAILABILITY_LINK: "AvailabilityLink",
  GROUP_VOTE_LINK: "GroupVoteLink",
  GROUP_SPREADSHEET_LINK: "GroupSpreadsheetLink",
};


export const GROUP_VOTE_HOLD_TEXT = {
  DESCRIPTION: "Vimcal will automatically update Smart Holds for any subsequent time slots you add or remove.",
  TITLE: "Holds are taken care of for you!",
};
