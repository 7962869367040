export const EXPANDED_ATTENDEES_CONTAINER = "EXPANDED_ATTENDEES_CONTAINER";
export const EXPANDED_SCROLL_CONTAINER = "EXPANDED_SCROLL_CONTAINER";
export const EVENT_FORM_ATTENDEE_SCROLL_CONTAINER =
  "EVENT_FORM_ATTENDEE_SCROLL_CONTAINER";
export const EVENT_FORM_SCROLL_CONTAINER = "EVENT_FORM_SCROLL_CONTAINER";
export const POP_UP_CONTAINER_ID = "POP_UP_CONTAINER_ID";
export const COLOR_SELECTOR_ID = "COLOR_SELECTOR_ID";
export const EXPANDED_VIEW_CONTAINER_ID = "event-expanded";
export const RSVP_SECTION_ID = "event-response-section";
export const HOVER_UPCOMING_EVENT_ID = "HOVER_UPCOMING_EVENT_ID";
export const POPUP_EXPANDED_VIEW_ID = "EXPANDED_POPUP_EXPANDED_VIEW";
export const AVAILABILITY_PANEL_ID = "availability-panel";
export const COMBINE_ADJACENT_SLOTS_ID = "combine-adjacent-slots-id";
export const EVENT_PANEL_WRAPPER_ID = "event-panel-wrapper";
export const PERSONAL_LINK_ANIMATION_ID = "personal-link-animation-id";
export const SHOW_EVERY_TIME_ZONE_ID = "settings-show-all-time-zones";
export const LAYOUT_ID = "layout";
export const REVERSE_SLOTS_CONTAINER_ID = "reverse-slots-container";
export const FIND_FREE_TIME_ID = "find-free-time-id";
export const PREVIOUSLY_SELECTED_SLOTS_MESSAGE =
  "use-previously-selected-slots-message";
export const GET_UPCOMING_WEEK_OPENINGS_MESSAGE = "get-upcoming-week-openings";
export const EVENT_MODAL_ID = "event-modal-id";
export const OUTSTANDING_SLOTS_SECTION_ID = "outstanding-slots-section";
export const NLP_INPUT_FIELD_NAME = "nlp-input-field";
export const EVENT_FORM_SAVE_BUTTON_ID = "event-form-save-button";
export const EVENT_FORM_SAVE_BOTTON_STICKY_CONTAINER_ID =
  "event-form-save-button-sticky-container";
export const GROUP_VOTE_DETAIL_CONTAINER = "group-vote-detail-container";
export const FIND_TIMES_MODAL_ID = "find-times-modal-id";
export const TUTURIAL_WIZARD_MODAL_ID = "tutorial-wizard-modal-id";
export const EMAIL_ATTENDEES_MODAL_ID = "email-attendees-modal";
export const REWIND_2023_MODAL = "rewind-2023-modal";
export const SLOTS_PAGE_ID = "slots-page-id";
export const EVENT_FORM_FIND_TIME_BUTTON_ID = "event-form-find-time-button";
export const FIND_TIME_TEXT_BOTTON = "find-time-text-button";
export const CALENDAR_LIST_FIND_TIME_BOTTON_ID =
  "calendar-list-find-time-button";
export const FIND_TIME_CAPSULE_ID = "find-time-capsule";
export const AVAILABILITY_PANEL_MODAL_ID = "availability-panel-modal-id";
export const MAIN_CALENDAR_ID = "main-calendar-id";
export const SLOTS_ATTENDEE_LOADING_ID = "SLOTS_ATTENDEE_LOADING_ID";
export const DEFAULT_CHECK_BOX_SPINNER_ID = "DEFAULT_CHECK_BOX_SPINNER_ID";
export const GROUP_VOTE_CALENDAR_VIEW_PREVIEW = "group-vote-calendar-view-preview";
export const CALENDAR_TYPE_DROPDOWN_ID = "calendar-type-dropdown";
export const AVAILABILITY_COPY_BUTTON_ID = "copy-availabiity-id";
export const ONBOARDING_AVAILABILITY_COPY_BUTTON_ID = "onboarding-copy-availabiity-id";
export const CREATE_GROUP_VOTE_BUTTON_ID = "create-group-vote-button";
export const RE_USE_SLOTS_BUTTON_ID = "re-use-slots-button";
export const EVENT_FORM_ID = "event-form";
export const EVENT_RESPONSE_MORE_OPTIONS = "event-response-more-options";
export const TRIAL_IS_OVER_MODAL_ID = "trial-is-over-modal";
export const REWIND_IS_READY_ID = "rewind-is-ready-id";
export const WEB_STYLES_ID = "web-styles-id";
