global.$RefreshReg$ = () => { }; // eslint-disable-line no-restricted-globals
// https://github.com/pmmmwh/react-refresh-webpack-plugin/issues/24
global.$RefreshSig$$ = () => () => { }; // eslint-disable-line no-restricted-globals
/* eslint-disable import/first */

import React from "react";

export default function CircleWithColor({ color, colorName, size = 15 }) {
  return (
    <div className="flex flex-row items-center">
      <div
        style={{
          border: color === "transparent" ? "dashed 1px gray" : "",
          backgroundColor: color,
          width: size,
          height: size,
          borderRadius: "50%",
          whiteSpace: "nowrap",
        }}
      />

      {colorName ? (
        <div
          className="ml-2 whitespace-nowrap truncate-text"
          style={{ width: "calc(100% - 23px)" }}
        >
          {colorName}
        </div>
      ) : null}
    </div>
  );
}
