import { constructRequestURL } from "../services/api";
import { getHomeLink } from "./envFunctions";

export function hasBasicInternetConnection() {
  // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
  // if false -> always offline
  // could return false positivies (could be off line when true)
  return navigator.onLine;
}

export async function hasInternetConnection() {
  if (!hasBasicInternetConnection()) {
    return false;
  }
  const isOnline = await checkOnlineStatus();
  if (!isOnline) {
    return false;
  }

  return true;
}

export async function checkOnlineStatus() {
  // more thorough check
  try {
    // added time param to avoid caching result: https://stackoverflow.com/questions/64331735/how-to-prevent-http-caching-of-rest-calls-in-browser
    const online = await fetch(constructRequestURL(`ping?t=${Date.now()}+ms`), {
      cache: "no-store",
    });
    return online.status === 200; // status 200 indicates that the the request has succeeded
  } catch (err) {
    return false; // definitely offline
  }
}

export async function isCloudFlareUp() {
  try {
    // added time param to avoid caching result: https://stackoverflow.com/questions/64331735/how-to-prevent-http-caching-of-rest-calls-in-browser
    const testLink = `${getHomeLink()}?time=${Date.now()}`;
    const response = await fetch(testLink, {
      method: "GET",
      cache: "no-store", // if cached -> this will return cached result so even if no internet -> returns last fetched version
    });
    if (response?.status !== 200) {
      return;
    }

    const parsedText = await response.text();
    return parsedText.includes("Vimcal") && parsedText.includes("isMenuBar");
  } catch (err) {
    return false; // definitely offline
  }
}
