import _ from "underscore";
import {
  removeCommaFromString,
  getRRuleStringFromRecurrence,
} from "../services/commonUsefulFunctions";
import { getISODay, startOfDay } from "date-fns";
import { RRule } from "rrule";
import { getEventEtag, getEventMasterEventID, getEventRecurrence, getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";

export function updateOriginalRecurringEventIndex(param) {
  const { currentEvent, originalEvent, originalRecurrenceEventIndex } = param;

  if (isEmptyObjectOrFalsey(currentEvent) || isEmptyObjectOrFalsey(originalEvent)) {
    return originalRecurrenceEventIndex;
  }

  // original recurrence index is an index of an index
  // need to separate out recurrence id per calendar because different calenders could pull different recurrence events
  let updatedIndex = _.clone(originalRecurrenceEventIndex);
  if (!updatedIndex[getEventUserCalendarID(currentEvent)]) {
    updatedIndex[getEventUserCalendarID(currentEvent)] = {};
  }

  let uniqueRecurringKey = determineOriginalEventIndexKey(currentEvent);

  updatedIndex[getEventUserCalendarID(currentEvent)][uniqueRecurringKey] =
    originalEvent;

  return updatedIndex;
}

export function resetOriginalRecurringEventIndex(param) {
  const { currentEvent, originalRecurrenceEventIndex } = param;

  const updatedIndex = _.clone(originalRecurrenceEventIndex);
  updatedIndex[getEventUserCalendarID(currentEvent)] = {};

  return updatedIndex;
}

function determineOriginalEventIndexKey(event) {
  if (!getEventMasterEventID(event)) {
    return null;
  }

  // need etag because if event changes, we don't want to grab an old cache
  // caveat: for recurring events that are one off (part of series but changed this event), we won't be able to cache it at the beginning
  return `${getEventMasterEventID(event)}_${removeCommaFromString(getEventEtag(event))}`;
}

export function getOriginalRecurringEventFromIndex(
  event,
  originalRecurrenceEventIndex,
  originalEventUserCalendarID
) {
  if (isEmptyObjectOrFalsey(event) || isEmptyObjectOrFalsey(originalRecurrenceEventIndex)) {
    return null;
  }

  // since we don't strictly remove stale cache, this could cause an error somewhere down the road
  const key = determineOriginalEventIndexKey(event);

  if (!key) {
    return null;
  }

  if (originalEventUserCalendarID &&
    !!originalRecurrenceEventIndex[originalEventUserCalendarID]
    && !!originalRecurrenceEventIndex[originalEventUserCalendarID][key]
    ) {
    // get master event recurring info from the original calendar that you copied from
    return (
      originalRecurrenceEventIndex[originalEventUserCalendarID][key]
    );
  }

  return (
    originalRecurrenceEventIndex[getEventUserCalendarID(event)] &&
    originalRecurrenceEventIndex[getEventUserCalendarID(event)][key]
  );
}

export function originalEventToRecurringEventIndexKey(recurringEventId, email) {
  return `${recurringEventId}_${email}`;
}

// For Google's API, a given instance is the first
// instance of a recurring event if its
// 'originalStartTime' key is equal to the 'start'
// key of the master copy of the recurring event
export function isEventFirstRecurringInstance(
  instanceOriginalStartTime,
  recurringEventStartTime
) {
  const isSameDateTime =
    !!instanceOriginalStartTime.dateTime &&
    !!recurringEventStartTime.dateTime &&
    instanceOriginalStartTime.dateTime === recurringEventStartTime.dateTime;
  const isSameDate =
    !!instanceOriginalStartTime.date &&
    !!recurringEventStartTime.date &&
    instanceOriginalStartTime.date === recurringEventStartTime.date;
  const isSameTimeZone =
    instanceOriginalStartTime.timeZone === recurringEventStartTime.timeZone;

  return (isSameDateTime || isSameDate) && isSameTimeZone;
}

export function trimOriginalRecurrenceRule(ruleString, cutoffDate) {
  // Combining UNTIL and COUNT in the same RRULE results in the rule being
  // rejected by Google with: Google::Apis::ClientError invalid: Invalid recurrence rule.
  // https://tools.ietf.org/html/rfc5545#section-3.3.10
  
  let rule = RRule.fromString(cleanRRuleString(ruleString)).origOptions;
  rule.until = startOfDay(cutoffDate);
  rule.count = null; // having count here breaks deletion. This is some what of a hack since it breaks because the count is incorrect but still works because we have until

  return new RRule(rule).toString();
}

// keep same rule except update the day of week
export function updateRecurrenceWithNewDayOfWeek(rruleString, newDate) {
  // Combining UNTIL and COUNT in the same RRULE results in the rule being
  // rejected by Google with: Google::Apis::ClientError invalid: Invalid recurrence rule.
  // https://tools.ietf.org/html/rfc5545#section-3.3.10
  
  // if multiple days -> keep by weekday
  // if only one day -> move day
  // origOptions:
    // [Weekday]
    // 2
  const rule = RRule.fromString(cleanRRuleString(rruleString)).origOptions;
  const repeatOnWeekDays = getRepeatOnWeekDay(rule);
  
  if (!rule.byweekday
    || repeatOnWeekDays.length === 0 
    || repeatOnWeekDays.length > 1
  ) {
    return rruleString
  }

  //! do not go into the object to set it
  // rule.byweekday = [{
  //   weekday: getISODay(newDate) - 1,
  //   n: rule.byweekday[0].n
  // }];
  rule.byweekday = getISODay(newDate) - 1;
  return new RRule(rule).toString();
}

export function getParsedRRUle(rruleString) {
  return RRule.fromString(cleanRRuleString(rruleString))
}

export function getRepeatOnWeekDay(rrule) {
  if (!rrule?.byweekday || rrule.byweekday.length === 0) {
    return [];
  }

  return rrule.byweekday;
}

export function isDayOfWeekInByWeekDayRecurrence(rrule, date) {
  const repeatOnWeekDays = getRepeatOnWeekDay(rrule);

  if (repeatOnWeekDays.length === 0) {
    return false;
  }
  
  const includedWeekDays = repeatOnWeekDays.map(day => day.weekday);
  const dateDayOfWeek = getISODay(date) - 1;
  return includedWeekDays.includes(dateDayOfWeek);
}

export function updatedRecurrenceAfterEventStartChange(originalRecurringEventInstance, startDate) {
  const originalRecurrenceString = getRRuleStringFromRecurrence(originalRecurringEventInstance);
  if (!originalRecurrenceString) {
    return [""];
  }

  const originalRecurrence = getEventRecurrence(originalRecurringEventInstance);
  // need to update recurrence if start date changed
  const originalRRule = RRule.fromString(cleanRRuleString(originalRecurrenceString)).origOptions;

  // you could have multiple days in originalRRule.byweekday
  if (getRepeatOnWeekDay(originalRRule).length === 1
    && !isDayOfWeekInByWeekDayRecurrence(originalRRule, startDate)
  ) {
    return [updateRecurrenceWithNewDayOfWeek(originalRecurrenceString, startDate)]
  }

  return originalRecurrence;
}

export function cleanRRuleString(rruleString) {
  if (!rruleString) {
    return "";
  }
  // need to filer out removeBusyMacParam
  // remove exclude for fullCalendar: https://stackoverflow.com/questions/56580678/is-exdate-not-included-in-rrule-for-full-calendar
  // const testString = "FREQ=WEEKLY;DTSTART=20190607T090000;EXDATE=20190705T090000;INTERVAL=4;BYDAY=FR"
  
  const removedBusyMac = removeBusyMacParam(rruleString);
  // const removeExcludeDate = removedBusyMac
  //   // .replaceAll(/EXDATE.*;/g, '') // delete EXDATE to ;
  //   .replaceAll("EXDATE", ""); // sanity check to delete rest of exdate
  //   console.log("removeExcludeDate_", removeExcludeDate);
  return removedBusyMac
}

function removeBusyMacParam(rRuleString) {
  // busy cal adds this param which breaks rrule
  if (!rRuleString) {
    return "";
  }

  if (rRuleString.includes("X-BUSYMAC-REGENERATE=TRASH")) {
    return rRuleString
      .replace(/X-BUSYMAC-REGENERATE=TRASH;/g, "")
      .replace(/;X-BUSYMAC-REGENERATE=TRASH/g, "")
      .replace(/X-BUSYMAC-REGENERATE=TRASH/g, "")
      .trim();
  }

  return rRuleString;
}
