import { v4 as uuidv4 } from "uuid";

/**
 * Returns a random integer between the min and max, inclusive.
 */
export function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max + 1 - min) + min);
}

/**
 * Returns a random integer between 0 and 99,999,999, inclusive.
 */
export function generateLargeRandomNumber() {
  return randomNumber(0, 99_999_999);
}

export function createUUID(length?: number) {
  if (!length) {
    return uuidv4();
  }

  return uuidv4().slice(0, length);
}
