import { containsAllElements, isEmptyArray } from "../../../lib/arrayFunctions";
import googleColors, { primaryEventsColors } from "../../../services/googleColors";

// sample data:
// [
//   {
//     color_id: "1",
//     label: "label1",
//   },
// ];
function getAllColorsMinusPrimary() {
  return googleColors.event;
}

export function hasColorLabelUsedAllColors(colorLabels) {
  if (isEmptyArray(colorLabels)) {
    return false;
  }
  const colorKeys = Object.keys(getAllColorsMinusPrimary());
  const colorLabelKeys = colorLabels.map((label) =>
    getColorLabelColorID(label)
  );
  return containsAllElements(colorLabelKeys, colorKeys);
}

export function getColorLabelColorID(label) {
  return label?.color_id;
}

export function getColorLabelHexColor(label) {
  const colorID = getColorLabelColorID(label);
  return primaryEventsColors[parseInt(colorID)]?.color;
}

export function getColorLabelLabel(label) {
  return label?.label;
}

export function getNextUnusedColorID(colorLabels) {
  const colorKeys = Object.keys(getAllColorsMinusPrimary());
  const colorLabelKeys = colorLabels.map((label) =>
    getColorLabelColorID(label)
  );
  const unusedColors = colorKeys.filter(
    (colorKey) => !colorLabelKeys.includes(colorKey)
  );
  return unusedColors[0];
}

export function getAllColorLabelIds(colorLabels) {
  return colorLabels?.map((label) => getColorLabelColorID(label)) ?? [];
}

export const SAMPLE_DATA = [
  {
    color_id: "1",
    label: "interview",
  },
  {
    color_id: "2",
    label: "1:1",
  },
  {
    color_id: "3",
    label: "design",
  },
];

export function doesColorLabelsContainColorID(colorLabels, colorID) {
  if (isEmptyArray(colorLabels) || !colorID) {
    return false;
  }
  return getAllColorLabelIds(colorLabels).includes(colorID);
}

export function getMatchingColorLabel({colorLabels, colorID}) {
  if (isEmptyArray(colorLabels)) {
    return;
  }
  return colorLabels.find((label) => getColorLabelColorID(label) === colorID);
}
