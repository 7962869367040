import _                                    from 'underscore';
import {
  sendBreadcrumbToSentry,
  breadcrumbData
}                                           from './commonUsefulFunctions';
import * as Sentry                          from '@sentry/browser';

export default (() => {
  return {
    subscriptions: {},

    subscribe(event, callback) {
      if (!this.subscriptions[event]) {
        this.subscriptions[event] = [];
      }

      this.subscriptions[event] = callback;
    },

    unsubscribe(event, callback) {
      if (this.subscriptions[event]) {
        this.subscriptions = _.omit(this.subscriptions, event);
      }
    },

    publish(event, ...args) {
      sendBreadcrumbToSentry({
        category: 'Broadcast',
        message: event,
        // include args if none are array/object, to mitigate 413 error risk
        data: breadcrumbData(args)
      })
      if (this.subscriptions[event]) {
        this.subscriptions[event](...args);
      } else {
        sendBreadcrumbToSentry({
          category: 'Broadcast',
          message: `${event} doesn't exist`,
          level: Sentry.Severity.Warning
        });
      }
    }
  };
})();
