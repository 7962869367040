import { checkForNaNDefaultValue, convertTrueFalseStringIntoValue, guessTimeZone, isValidTimeZone } from "../services/commonUsefulFunctions";
import GoogleCalendarService, { BACKEND_HANGOUT, BACKEND_PHONE, GOOGLE_MDY_FORMAT } from "../services/googleCalendarService";
import { isEmptyArrayOrFalsey, isEmptyObjectOrFalsey, isNullOrUndefined } from "../services/typeGuards";
import { getSettingValue } from "../services/settingsAccessors";
import { BACKEND_SETTINGS_NAMES } from "./vimcalVariables";
import { isEmptyArray } from "./arrayFunctions";
import { filterOutInvalidTimeZones } from "./timeFunctions";
import { BACKEND_WEEK, DEFAULT_AM_BAR, DEFAULT_PM_BAR, ZOOM_UNIQUE } from "../services/globalVariables";
import { isOutlookUser } from "./outlookFunctions";
import { shouldDisplayColorLabel } from "./featureFlagFunctions";
import { isLocal } from "../services/devFunctions";

/************************************/
/* Master Account Specific Settings */
/************************************/

export function shouldMergeEvents({ masterAccount }) {
  const autoMergeEvents = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.AUTO_MERGE_EVENTS,
    masterAccount,
  });

  if (isNullOrUndefined(autoMergeEvents)) {
    return true;
  }

  return convertTrueFalseStringIntoValue(autoMergeEvents);
}

export function isUserInDarkMode({ masterAccount }) {
  const darkMode = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DARK_MODE,
    masterAccount,
  });

  if (isNullOrUndefined(darkMode)) {
    return true;
  }

  return convertTrueFalseStringIntoValue(darkMode);
}

export function getAccountDateFormat({ masterAccount, user }) {
  const dateFormat = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DATE_FORMAT,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(dateFormat)) {
    return GOOGLE_MDY_FORMAT;
  }

  return dateFormat;
}

export function shouldDimPastEvents({ masterAccount }) {
  const dimPastEvents = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DIM_PAST_EVENTS,
    masterAccount,
  });

  if (isNullOrUndefined(dimPastEvents)) {
    return true;
  }

  return convertTrueFalseStringIntoValue(dimPastEvents);
}

export function getMasterAccountShouldHideAllDayBusyColumn({ masterAccount }) {
  const hideBusyColumn = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.HIDE_BUSY_COLUMN,
    masterAccount,
  });

  if (isNullOrUndefined(hideBusyColumn)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(hideBusyColumn);
}

export function shouldHideDefaultSignature({ masterAccount, user }) {
  const hideDefaultSignature = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.HIDE_DEFAULT_SIGNATURE,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(hideDefaultSignature)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(hideDefaultSignature);
}

export function getMasterAccountShouldHideOOOColumn({ masterAccount }) {
  const hideOOOColumn = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.HIDE_OOO_COLUMN,
    masterAccount,
  });

  if (isNullOrUndefined(hideOOOColumn)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(hideOOOColumn);
}

export function getAccountHideWeekend({ masterAccount }) {
  const hideWeekends = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.HIDE_WEEKENDS,
    masterAccount,
  });

  if (isNullOrUndefined(hideWeekends)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(hideWeekends);
}

export function shouldMatchOSSetting({ masterAccount }) {
  const matchOSSchemeDesktop = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.MATCH_OS_SCHEME_DESKTOP,
    masterAccount,
  });

  if (isNullOrUndefined(matchOSSchemeDesktop)) {
    return true;
  }

  return convertTrueFalseStringIntoValue(matchOSSchemeDesktop);
}

export function getMenuBarCalendars({ masterAccount }) {
  const menuBarCalendars = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.MENU_BAR_CALENDARS,
    masterAccount,
  });

  if (isEmptyArray(menuBarCalendars)) {
    return [];
  }

  return menuBarCalendars;
}

export function getIsAccountIn24HourFormat({ masterAccount, user }) {
  const militaryTime = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.MILITARY_TIME,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(militaryTime)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(militaryTime);
}

export function showAccountDeclinedEvents({ masterAccount }) {
  const showDeclinedEvents = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.SHOW_DECLINED_EVENTS,
    masterAccount,
  });

  if (isNullOrUndefined(showDeclinedEvents)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(showDeclinedEvents);
}

export function getAccountStartOfWeek({ masterAccount }) {
  const startOfWeek = checkForNaNDefaultValue({
    value: parseInt(getSettingValue({
      backendSettingName: BACKEND_SETTINGS_NAMES.START_OF_WEEK,
      masterAccount,
    })),
    defaultValue: 0,
  });

  // react big calendar only accepts 0-6
  if (![0, 1, 2, 3, 4, 5, 6].includes(startOfWeek)) {
    return 0;
  }

  return startOfWeek;
}

export function shouldDisplayWeekNumber({ masterAccount }) {
  const displayWeekNumber = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.WEEK_NUMBER,
    masterAccount,
  });

  if (isNullOrUndefined(displayWeekNumber)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(displayWeekNumber);
}

export function getIsWelcomeTourDismissed({ masterAccount }) {
  const welcomeTourDismissed = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.WELCOME_TOUR_DISMISSED,
    masterAccount,
  });

  if (isNullOrUndefined(welcomeTourDismissed)) {
    return false;
  }

  return convertTrueFalseStringIntoValue(welcomeTourDismissed);
}

/*****************************/
/* Delegated Shared Settings */
/*****************************/

export function getAnchorTimeZonesInSettings({ user, masterAccount }) {
  const anchorTimeZones = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.ANCHOR_TIME_ZONES,
    masterAccount,
    user,
  });

  if (isEmptyArray(anchorTimeZones)) {
    return [];
  }

  return filterOutInvalidTimeZones(anchorTimeZones);
}

export function shouldAutoAddEmoji({ masterAccount, user }) {
  const autoEmojis = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.AUTO_EMOJIS,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(autoEmojis)) {
    return true;
  }

  return convertTrueFalseStringIntoValue(autoEmojis);
}

// We seem to only use masterAccount for this
export function getAccountRawCalendarView({ masterAccount, user }) {
  const calendarView = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.CALENDAR_VIEW,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(calendarView)) {
    return BACKEND_WEEK;
  }

  return calendarView;
}

export function getDefaultMeetingLength({ masterAccount, user }) {
  const defaultMeetingLength = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DEFAULT_MEETING_DURATION,
    masterAccount,
    user,
  });

  return checkForNaNDefaultValue({
    defaultValue: 30,
    value: parseInt(defaultMeetingLength),
  });
}

/* Where was this used? */
export function getUserSettingsLocalTimeZone({ masterAccount, user }) {
  const localTimeZone = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.LOCAL_TIME_ZONE,
    masterAccount,
    user,
  });

  if (isNullOrUndefined(localTimeZone)) {
    return guessTimeZone();
  }

  return localTimeZone;
}

export function getUserAccountOrderedTimeZone({ masterAccount, user}) {
  const orderedTimeZone = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.ORDERED_TIME_ZONES,
    masterAccount,
    user,
  });

  if (isEmptyArray(orderedTimeZone)) {
    return [];
  }

  return orderedTimeZone;
}

/// this gets the override time zone for the user (as of Nov 27th 2022, we only use this for maestro delegated user)
function getTimeZoneOverride({ masterAccount, user }) {
  const timeZoneOverride = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.TIME_ZONE_OVERRIDE,
    masterAccount,
    user,
  });

  if (!isValidTimeZone(timeZoneOverride)) {
    return;
  }

  return timeZoneOverride;
}

export function shouldUseTimeZoneOverride({ masterAccount, user }) {
  const timeZoneOverride = getTimeZoneOverride({ masterAccount, user });
  const useTimeZoneOverride = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.USE_TIME_ZONE_OVERRIDE,
    masterAccount,
    user,
  });

  /* Require both timeZOneOverride and useTimeZoneOverride */
  if (
    isNullOrUndefined(useTimeZoneOverride) ||
    isNullOrUndefined(timeZoneOverride)
  ) {
    return false;
  }

  return convertTrueFalseStringIntoValue(useTimeZoneOverride);
}

export function getDefaultUserTimeZone({ masterAccount, user }) {
  if (shouldUseTimeZoneOverride({ masterAccount, user })) {
    return getTimeZoneOverride({ masterAccount, user });
  }

  return guessTimeZone();
}

export function getTrips({ masterAccount, user }) {
  const trips = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.TRIPS,
    masterAccount,
    user,
  });

  if (isEmptyArray(trips)) {
    return [];
  }

  return trips;
}

export function getWorkHours({ masterAccount, user }) {
  const workHours = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.WORK_HOURS,
    masterAccount,
    user,
  });

  if (isEmptyObjectOrFalsey(workHours)) {
    return { startWorkHour: DEFAULT_AM_BAR, endWorkHour: DEFAULT_PM_BAR };
  }

  const startWorkHour = checkForNaNDefaultValue({
    value: parseInt(workHours?.start),
    defaultValue: DEFAULT_AM_BAR,
  });
  const endWorkHour = checkForNaNDefaultValue({
    value: parseInt(workHours?.end),
    defaultValue: DEFAULT_PM_BAR,
  });

  return { startWorkHour, endWorkHour };
}

export function getColorLabels({
  masterAccount,
  user,
  // shouldPrint = false,
}) {
  if (!shouldDisplayColorLabel({ user })) {
    return [];
  }
  const colorLabels = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.COLOR_LABELS,
    masterAccount,
    user,
  });

  // check masterAccount for non-maestro user
  if (isNullOrUndefined(colorLabels)) {
    // if doesn't exist -> set to true by default
    // if (isLocal()) {
    //   return SAMPLE_DATA; // TODO: delete!!!!
    // }
    return [];
  }

  return colorLabels;
}

export const SEND_EMAIL_AS = {
  EXECUTIVE: "executive",
  EA: "ea",
};
export function getDefaultEmailSendAsOption({ masterAccount }) {
  const sendAsValue = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DEFAULT_SELF_AS_SENDER,
    masterAccount,
  });

  // check masterAccount for non-maestro user
  if (isNullOrUndefined(sendAsValue) ||
    !Object.values(SEND_EMAIL_AS).includes(sendAsValue)
  ) {
    // if doesn't exist -> set to true by default
    return SEND_EMAIL_AS.EXECUTIVE;
  }

  return sendAsValue;
}

export function shouldHideWorkingLocationEvents({ masterAccount, user }) {
  const hideWorkLocationEvents = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.HIDE_WORK_LOCATION_EVENTS,
    masterAccount,
    user,
  });

  // check masterAccount for non-maestro user
  if (isNullOrUndefined(hideWorkLocationEvents)) {
    // if doesn't exist -> set to true by default
    return false;
  }

  return convertTrueFalseStringIntoValue(hideWorkLocationEvents);
}

/**************************/
/* User Specific Settings */
/**************************/

export function getContactGroups({ user }) {
  const contactGroups = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.CONTACT_GROUPS,
    user,
  });

  if (isEmptyArray(contactGroups)) {
    return [];
  }

  return contactGroups;
}

export function getCustomConferencingName({ user }) {
  const userCustomConferencingName = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_NAME,
    user,
  });

  if (userCustomConferencingName?.length > 0) {
    return userCustomConferencingName;
  }

  return "";
}

export function getCustomConferencingURL({ user }) {
  const customConferencingURL = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.CUSTOM_CONFERENCING_URL,
    user,
  });

  if (customConferencingURL?.length > 0) {
    return customConferencingURL;
  }

  return "";
}

export function getDefaultUserConferencing({ user }) {
  const defaultConferencing = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DEFAULT_CONFERENCING_OPTION,
    user,
  });

  /* If user is Outlook and default set to Google Hangout we return null */
  if (isOutlookUser(user) && defaultConferencing === BACKEND_HANGOUT) {
    return null;
  }

  return defaultConferencing;
}

export function getDefaultPhoneOption({ user }) {
  const defaultPhoneOption = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DEFAULT_PHONE_OPTION,
    user,
  });

  if (isNullOrUndefined(defaultPhoneOption)) {
    return BACKEND_PHONE;
  }

  return defaultPhoneOption;
}

export function getDefaultZoomMode({ user }) {
  const defaultZoomMode = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.DEFAULT_ZOOM_MODE,
    user,
  });

  if (isNullOrUndefined(defaultZoomMode)) {
    return ZOOM_UNIQUE;
  }

  return defaultZoomMode;
}

export function determineDefaultGuestPermissions({ user }) {
  const {
    modifyEventsString,
    inviteOthersString,
    seeGuestListString,
  } = GoogleCalendarService;

  const guestPermissionModifyEvents = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_MODIFY_EVENTS,
    user,
  });

  /* Default if missing modify event permissions */
  if (isNullOrUndefined(guestPermissionModifyEvents)) {
    return [null, inviteOthersString, seeGuestListString];
  }

  const guestPermissionInviteOthers = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_INVITE_OTHERS,
    user,
  });

  const guestPermissionSeeGuestist = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.GUEST_PERMISSION_SEE_GUEST_LIST,
    user,
  });

  /* All 3 checkboxes enabled if modify events is true */
  if (guestPermissionModifyEvents) {
    return [modifyEventsString, inviteOthersString, seeGuestListString];
  } else {
  /* Set only GUEST_PERMISSION_INVITE_OTHERS and GUEST_PERMISSION_SEE_GUEST_LIST */
    return [
      null,
      guestPermissionInviteOthers
        ? inviteOthersString
        : null,
      guestPermissionSeeGuestist
        ? seeGuestListString
        : null,
    ];
  }
}

export function getInternalDomainAndEmails({ masterAccount, user }) {
  // if (isLocal) {
  //   return ["vimcal.com", "weveapp.com", "mchlzhao@gmail.com"]; // for testing purpose only
  // }
  const internalDomains = getSettingValue({
    backendSettingName: BACKEND_SETTINGS_NAMES.INTERNAL_DOMAINS,
    masterAccount,
    user,
  });

  if (isEmptyArrayOrFalsey(internalDomains)) {
    return [];
  }

  return internalDomains;
}
