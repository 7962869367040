import { getCalendarOwnerEmail } from "../services/calendarAccessors";
import { TEMPORARY } from "../services/googleCalendarService";
import { getBackgroundHexFromGoogleColorID } from "../services/googleColors";
import _ from "underscore";
import { getCalendarFromUserCalendarID } from "./calendarFunctions";
import { getEventUserCalendarID } from "../services/eventResourceAccessors";
import { isEmptyObjectOrFalsey } from "../services/typeGuards";

const UPDATE_SINGLE_EVENT = "UPDATE_SINGLE_EVENT";
const CREATE_SINGLE_EVENT = "CREATE_SINGLE_EVENT";
const CREATE_RECURRING_EVENTS = "CREATE_RECURRING_EVENTS";
const DELETE_SINGLE_EVENT = "DELETE_SINGLE_EVENT";

const MIMIC_EVENT = "mimic_event";
export const MIMIC_TYPE = {
  [CREATE_SINGLE_EVENT]: CREATE_SINGLE_EVENT,
  [CREATE_RECURRING_EVENTS]: CREATE_RECURRING_EVENTS,
  [UPDATE_SINGLE_EVENT]: UPDATE_SINGLE_EVENT,
  [DELETE_SINGLE_EVENT]: DELETE_SINGLE_EVENT,
};

export function isFakeMimicEvent(event) {
  return event?.vimcalType === MIMIC_EVENT;
}

export function setEventToMimicEvent({ event, allCalendars }) {
  if (isEmptyObjectOrFalsey(event)) {
    return null;
  }

  return _.omit(
    {
      ...event,
      vimcalType: MIMIC_EVENT,
      status: TEMPORARY,
      updated_at: new Date().toISOString(),
      resourceId:
        event.resourceId ||
        getCalendarOwnerEmail(
          getCalendarFromUserCalendarID({
            userCalendarID: getEventUserCalendarID(event),
            allCalendars,
          })
        ),
    },
    "etag",
    "uniqueEtag"
  );
}

export function isTemporaryEventRecurringEvent(eventData) {
  return !!eventData?.calendar_event?.recurrence;
}

export function createUpdatedSingleEventWithColor({
  event,
  calendarColor = null,
  colorID,
  extendedProperties = {},
}) {
  if (!colorID || isEmptyObjectOrFalsey(event)) {
    return null;
  }

  // 1. take event
  // 2. make it a temporary event and vimcal mimic event (with setEventToMimicEvent)
  // 3. set the color
  // 4. return the event
  const { color, fadedColor } = getBackgroundHexFromGoogleColorID(colorID); // both come back as Hex
  if (!color) {
    return;
  }
  const colorExcludingDefault =
    colorID === "0" && calendarColor ? calendarColor : color;

  return {
    ...event,
    backgroundColor: colorExcludingDefault,
    normalColor: colorExcludingDefault,
    fadedColor,
    color_id: colorID,
    event_color: colorExcludingDefault,
    extended_properties: extendedProperties,
  };
}

export function createUpdatedSingleEventWithEventResponse({
  event,
  eventAttendingStatus,
}) {
  if (!eventAttendingStatus?.attendees || isEmptyObjectOrFalsey(event)) {
    return null;
  }
  const { attendees } = eventAttendingStatus;

  const rawJson = event.raw_json ?? {};
  rawJson.attendees = attendees;

  return {
    ...event,
    attendees: attendees,
    raw_json: rawJson,
  };
}
