import mixpanel from "mixpanel-browser";
import { handleError } from "../services/commonUsefulFunctions";
import { trackEvent } from "../components/tracking";
import { getAccountState } from "./stateManagementFunctions";
import { ACCOUNT_STATE_TRIAL, ALL_CANCELLED_STATE } from "./vimcalVariables";
import { getUserToken } from "./userFunctions";

export function initMixPanel() {
  try {
    mixpanel?.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  } catch (error) {
    handleError(error);
  }
}

function getMixpanelTrackingID({ masterAccount }) {
  return masterAccount?.id;
}

export function identifyMixPanel({ masterAccount }) {
  const mixpanelID = getMixpanelTrackingID({ masterAccount });
  // will try error if mixpanel.init with null and thus you can not log in
  if (!mixpanelID) {
    return;
  }
  try {
    // will try error if mixpanel.init with null and thus you can not log in
    // https://docs.mixpanel.com/docs/tracking/how-tos/identifying-users
    mixpanel?.identify(mixpanelID);
  } catch (err) {
    handleError(err);
  }
}

export function resetMixPanel() {
  try {
    // will throw error if mixpanel.init with null value
    mixpanel?.reset(); // https://docs.mixpanel.com/docs/tracking/how-tos/identifying-users
  } catch (error) {
    handleError(error);
  }
}

export const SUBSCRIPTION_LENGTH = {
  ANNUAL: "annual",
  MONTHLY: "monthly",
};

export const PAYMENT_CONVERSATION_TYPE = {
  TRIAL_TO_PAID: "trial_to_paid",
  CHURNED_TO_PAID: "churned_to_paid",
};

export function getConversionType({ masterAccount }) {
  const accountState = getAccountState(masterAccount);
  if (ALL_CANCELLED_STATE.includes(accountState)) {
    return PAYMENT_CONVERSATION_TYPE.CHURNED_TO_PAID;
  }

  if (accountState === ACCOUNT_STATE_TRIAL) {
    return PAYMENT_CONVERSATION_TYPE.TRIAL_TO_PAID;
  }

  return `${accountState}_to_paying`; // other states to paying
}

export function trackPaywallConversionMixPanel({
  currentUser,
  subscriptionFrequency, // SUBSCRIPTION_LENGTH
  conversionType, // PAYMENT_CONVERSATION_TYPE
}) {
  mixpanelTrack({
    eventName: conversionType,
    eventData: {
      subscription_frequency: subscriptionFrequency,
      quantity: 1,
    },
  });

  trackEvent({
    action: `${conversionType}_BQ`,
    category: `${subscriptionFrequency}_${conversionType}`,
    label: "conversion_tracking",
    userToken: getUserToken(currentUser),
  });
}

function mixpanelTrack({ eventName, eventData }) {
  if (!eventName) {
    return;
  }
  try {
    // eventName: "Played song"
    // eventData: { genre: "hip-hop" }
    // e.g. mixpanel.track("Played song", { genre: "hip-hop" });
    // https://docs.mixpanel.com/docs/tracking/reference/javascript
    mixpanel?.track(eventName, eventData);
  } catch (error) {
    handleError(error);
  }
}
