import { handleError } from "../services/commonUsefulFunctions";

export function clampValueBetween0And100(value, startClamp, endClamp) {
  if (!value) {
    return startClamp || 0;
  }
  return Math.min(endClamp || 100, Math.max(startClamp || 0, value));
}

export function isZero(value) {
  return typeof value === "number" && value === 0;
}

// the hash value is deterministic - this function will return the same value given the same input
// this is a simplier version of the djb2 hash function
// really quick has for string: http://www.cse.yorku.ca/~oz/hash.html
// has excellent distribution and speed on many different sets of keys and table sizes
export function hashCode(str) {
  try {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  } catch (error) {
    handleError(error);
  }
}

/**
 * checks if the id should be in a certain bucket based on a hashed percent.
 * The primary reason to use hashing is to handle non-uniformly distributed IDs or when IDs are strings or other types.
 * @param {int} id  - usually a master account id, etc
 * @param {int} percentage - The author of the book.
 */
export function isInBucket({ id, percentage }) {
  if (!id || !percentage) {
    return false;
  }
  try {
    const hashValue = Math.abs(hashCode(id.toString()));
    return hashValue % 100 <= percentage;
  } catch (error) {
    handleError(error);
    return false;
  }
}
