import { zoomImageURL } from "../services/globalVariables";
import PhoneNumber from "awesome-phonenumber";
import { isUserMaestroUser } from "./maestroFunctions";
import { isEmptyArray } from "../lib/arrayFunctions";
import { getZoomJoinURL } from "../lib/zoomFunctions";
import { createZoomNoteFromUniqueZoom } from "./googleCalendarService";
import { lowerCaseAndTrimString } from "../lib/stringFunctions";
import { isEmptyObjectOrFalsey } from "./typeGuards";

export function createUniqueZoom(zoomMeeting) {
  let videoConferencingOption = {
    entryPointType: "video",
    uri: getZoomJoinURL(zoomMeeting),
    label: getZoomJoinURL(zoomMeeting),
    meetingCode: zoomMeeting.id,
  };

  const conferenceSolution = {
    key: {
      type: "addOn",
    },
    name: "Zoom Meeting",
    iconUri: zoomImageURL,
  };

  let entryPoints = [videoConferencingOption];

  let { phoneInfo } = getZoomMeetingDetails(zoomMeeting);

  let phoneOption;
  if (phoneInfo) {
    let phoneNumber = formatRFCPhoneNumber(phoneInfo.number, phoneInfo.country);
    phoneOption = {
      entryPointType: "phone",
      label: phoneNumber,
      regionCode: phoneInfo.country,
      uri: `${phoneNumber},,${zoomMeeting.id}#`,
    };
    entryPoints = entryPoints.concat(phoneOption);
  }

  const zoomConference = {
    entryPoints,
    conferenceSolution: conferenceSolution,
    notes: createZoomNoteFromUniqueZoom(zoomMeeting),
    id: zoomMeeting.id,
  };

  return zoomConference;
}

function getZoomMeetingDetails(zoomMeeting) {
  if (isEmptyObjectOrFalsey(zoomMeeting)) {
    return { phoneInfo: null };
  }

  let phoneInfo;
  if (
    zoomMeeting &&
    zoomMeeting.settings &&
    zoomMeeting.settings.global_dial_in_numbers &&
    zoomMeeting.settings.global_dial_in_numbers.length > 0
  ) {
    phoneInfo = zoomMeeting.settings.global_dial_in_numbers[0];
  }

  return { phoneInfo };
}

export function formatRFCPhoneNumber(phoneNumber, regionCode) {
  let pn = new PhoneNumber(phoneNumber, regionCode);

  let formattedPhoneNumber = pn.getNumber("rfc3966");

  return formattedPhoneNumber;
}

export function getMeetingIDAndPassword({ url }) {
  if (!url) {
    return { password: null, meetingID: null };
  }

  // Create a new URL object
  const zoomUrl = new URL(url);

  // Extract the values of the "pwd" and "id" parameters using the URLSearchParams object
  const params = new URLSearchParams(zoomUrl.search);
  const password = params.get("pwd");
  const meetingID = zoomUrl?.pathname?.split("/")?.pop();
  return { password, meetingID };
}

export function getUserIDFromScheduler(scheduler) {
  return scheduler?.user_id;
}

export function getZoomSchedulers(zoomSchedulers) {
  return zoomSchedulers?.schedulers;
}

function getZoomSchedulerID(scheduler) {
  return scheduler?.id;
}

function getZoomSchedulerEmail(scheduler) {
  return scheduler?.email;
}

export function getZoomSchedulerUser({
  schedulers,
  user,
  masterAccount
}) {
  // spec: https://docs.google.com/document/d/13Mp7D0qxCJyQ1boRj9FNmt9yJnW_KbsUTb2f39ZoI3s/edit
  if (!isUserMaestroUser(masterAccount) || isEmptyArray(schedulers) || !user?.email) {
    // if it's nto maestro, always return the user
    return user;
  }
  // userSchedulers:
  // {
  //   "user_id": 4,
  //   "email": "mike@vimcal.com",
  //   "schedulers": [
  //       {
  //           "id": "nCVTFUa4QkqkLCbJWsi0OQ",
  //           "email": "alex@vimcal.com",
  //           "pmi": 5906687175
  //       },
  //       {
  //           "id": "_f8jxVIHSO-t4cuU17WqQA",
  //           "email": "seamus@vimcal.com",
  //           "pmi": 4335695133
  //       }
  //   ]
  // }

  let matchingUser
  let matchingDelegatedSchedulerID;
  Object.values(schedulers).forEach(userSchedulers => { // userSchedulers: 
    if (!userSchedulers?.error) {
      // scheduler has to have error
      return;
    }
    // delegatedScheduler:
      //  {
      //           "id": "_f8jxVIHSO-t4cuU17WqQA",
      //           "email": "seamus@vimcal.com",
      //           "pmi": 4335695133
      //       }
      if (
        getZoomSchedulers(userSchedulers)?.map(scheduler => lowerCaseAndTrimString(scheduler?.email)).includes(lowerCaseAndTrimString(user.email))
        && (!matchingDelegatedSchedulerID || getZoomSchedulerID(userSchedulers) < matchingDelegatedSchedulerID)
      ) {
        matchingUser = userSchedulers;
        matchingDelegatedSchedulerID = getZoomSchedulerID(userSchedulers);
      }
  });

  return matchingUser ?? user;
}

export const ZOOM_VARIABLES = {
  ZOOM_SELECT_USER_IMG_DARK_MODE: "https://vimcal-app-images.s3.amazonaws.com/zoom-select-user-dark-mode.png",
  ZOOM_SELECT_USER_IMG_LIGHT_MODE: "https://vimcal-app-images.s3.amazonaws.com/zoom-select-user-light-mode.png",
  ZOOM_PERMISSION_SCREEN_DARK_MODE: "https://vimcal-app-images.s3.amazonaws.com/zoom-permission-shared-permissions-dark-mode.png",
  ZOOM_PERMISSION_SCREEN_LIGHT_MODE: "https://vimcal-app-images.s3.amazonaws.com/zoom-permission-shared-permissions-light-mode.png",
  ZOOM_PERMISSION_CHECK_BOX_DARK_MODE: "https://vimcal-app-images.s3.amazonaws.com/allow-app-to-use-shared-permissions-dark-mode.png",
  ZOOM_PERMISSION_CHECK_BOX_LIGHT_MODE: "https://vimcal-app-images.s3.amazonaws.com/allow-app-to-use-shared-permissions-light-mode.png",
}
