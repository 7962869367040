import { getQueryParam } from "../queryParamFunctions";

/*****************/
/* Routing Paths */
/*****************/

export const NEW_EA_LOGIN_PATH = "ea-new";
export const MAGIC_LINK_PATH = "magic-link";

/*************/
/* Variables */
/*************/

export const MAGIC_LINK_TOKEN_PARAM = "token";
export const MAGIC_LINK_TOKEN_LOCAL_DATA_VARIABLE = "magic-link-token";


/*************/
/* Functions */
/*************/

export function getMagicLinkTokenFromSearchParams() {
  // SEA/TODO: Figure out how to properly type this
  const params: any = getQueryParam();
  return params?.token;
}
