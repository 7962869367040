import { getCalendarColorHex } from "../services/calendarAccessors";
import {
  appendUserDataToGoogleLinks,
  isElectron,
  OpenLink,
  openLinkOnSamePage,
} from "../services/commonUsefulFunctions";
import {
  getEventColorID,
  getEventUserCalendarID,
  getEventUserEmail,
  getGoogleEventHTMLLink,
} from "../services/eventResourceAccessors";
import { SECOND_IN_MS } from "../services/globalVariables";
import googleColors from "../services/googleColors";
import { getRerouteForAddAccountOnboarding } from "../services/queryParamFunctions";
import { getHomeLink } from "./envFunctions";

const GOOGLE_LOGIN_SCOPES =
  "email profile https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly https://www.googleapis.com/auth/admin.directory.user.readonly";
const GOOGLE_API_KEY = "AIzaSyAuxEFGIveDj5qUTJZkDs-l87Z3MwV4EOc";
const GOOGLE_CLIENT_ID =
  "800204084418-p9v6g9o2t9lklvr3bdssqd5p20c1hagu.apps.googleusercontent.com";

// export const GOOGLE_REDIRECT_URL = `${getHomeLink()}/desktop-login`;
const GOOGLE_REDIRECT_URL = `${getHomeLink()}/login`;
const GOOGLE_ONBOARDING_URL = `${getHomeLink()}/welcome`;
const GOOGLE_DESKTOP_REDIRECT_URL = `${getHomeLink()}/desktop-login`;
const GOOGLE_MAGIC_LINK_REDIRECT_URL = `${getHomeLink()}/magic-link`;
const GOOGLE_LOGIN_STATE = "google_login";

const ACCOUNT_TYPE_START = "account_type_start_"; // need this to match substring
const ACCOUNT_TYPE_END = "_account_type_end"; // need this to match substring

// function isTestUsingConsent(userEmail) {
//   if (!userEmail) {
//     return false;
//   }
//   return userEmail.includes("ingenious.build");
// }

export function getGoogleLoginURL({
  isDesktopLogin = false,
  isInOnboarding, // if it's in add onboarding, we update the redirect route
  accountType = null,
  hint = null,
  // userEmail,
  // showConsentScreen = false,
  isMagicLink,
}) {
  // doc: https://developers.google.com/identity/protocols/oauth2/web-server#authorization-errors-redirect-uri-mismatch
  // https://developers.google.com/identity/openid-connect/openid-connect
  const url =
    "https://accounts.google.com/o/oauth2/auth?" +
    `client_id=${encodeURIComponent(GOOGLE_CLIENT_ID)}` +
    `&redirect_uri=${encodeURIComponent(
      getGoogleRedirectURL({ isDesktopLogin, isInOnboarding, isMagicLink }),
    )}` +
    `&scope=${encodeURIComponent(GOOGLE_LOGIN_SCOPES)}` +
    `&prompt=${encodeURIComponent("consent select_account")}` +
    "&access_type=offline" +
    "&include_granted_scopes=true" +
    `&state=${GOOGLE_LOGIN_STATE}${
      accountType
        ? `_${ACCOUNT_TYPE_START}${accountType}${ACCOUNT_TYPE_END}_`
        : ""
    }` +
    "&response_type=code" +
    `${hint ? `&login_hint=${encodeURIComponent(hint)}` : ""}
    + ${isInOnboarding ? getRerouteForAddAccountOnboarding() : ""}`;

  return url;
}

export function getGoogleRedirectURL({ isDesktopLogin, isInOnboarding, isMagicLink }) {
  if (isMagicLink) {
    return GOOGLE_MAGIC_LINK_REDIRECT_URL;
  }

  if (isInOnboarding) {
    return GOOGLE_ONBOARDING_URL;
  }
  return isDesktopLogin ? GOOGLE_DESKTOP_REDIRECT_URL : GOOGLE_REDIRECT_URL;
}

export function isGoogleAuthCode(search) {
  return search?.includes(GOOGLE_LOGIN_STATE);
}

export function onClickGoogleLogin({ hint, showConsentScreen, accountType }) {
  const inputHint = hint ?? "";
  if (isElectron()) {
    OpenLink(
      getGoogleLoginURL({
        isDesktopLogin: true,
        hint: inputHint,
        showConsentScreen,
        accountType,
      }),
    );
  } else {
    openLinkOnSamePage(
      getGoogleLoginURL({
        isDesktopLogin: false,
        hint: inputHint,
        showConsentScreen,
        accountType,
      }),
    );
  }
}

export function getAccountTypeFromAuthResponse(str) {
  const pattern = new RegExp(ACCOUNT_TYPE_START + "(.*)" + ACCOUNT_TYPE_END);
  if (str.match(pattern)) {
    return str.match(pattern)[1];
  }

  return null;
}

export function onClickCreateOOOEvent() {
  const url = "https://calendar.google.com/calendar/u/0/r";
  OpenLink(url);
}

export function onClickEditOriginalEvent(event) {
  const htmlLink = getGoogleEventHTMLLink(event);
  if (!htmlLink) {
    return;
  }
  // Create a URL object from the urlString
  const eventURL = new URL(htmlLink);

  const userEmail = getEventUserEmail(event);
  if (!userEmail) {
    return;
  }

  // Extract the 'eid' parameter value using URLSearchParams
  const eid = eventURL.searchParams.get("eid");
  // sample url: `https://calendar.google.com/calendar/u/6/r/proposetime/NWJsM2l0MTdpZmoydGQ3MXBnZjdia3BuNWMgZmVybmFuZG9yb2RkODhAZ21haWwuY29t`;
  const url = new URL(
    `https://calendar.google.com/calendar/u/6/r/eventedit/${eid}`,
  );
  const urlWithUser = appendUserDataToGoogleLinks(url, userEmail);
  OpenLink(urlWithUser);
}

export function onClickGoogleProposeTime({ event }) {
  const htmlLink = getGoogleEventHTMLLink(event);
  if (!htmlLink) {
    return;
  }
  // Create a URL object from the urlString
  const eventURL = new URL(htmlLink);

  const userEmail = event?.userEmail;
  if (!userEmail) {
    return;
  }

  // Extract the 'eid' parameter value using URLSearchParams
  const eid = eventURL.searchParams.get("eid");
  // sample url: `https://calendar.google.com/calendar/u/6/r/proposetime/NWJsM2l0MTdpZmoydGQ3MXBnZjdia3BuNWMgZmVybmFuZG9yb2RkODhAZ21haWwuY29t`;
  const url = new URL(
    `https://calendar.google.com/calendar/u/6/r/proposetime/${eid}`,
  );
  const urlWithUser = appendUserDataToGoogleLinks(url, userEmail);
  OpenLink(urlWithUser);
}

export function doesGoogleEventHaveDefaultColor({ event, allCalendars }) {
  const eventColorID = getEventColorID(event);
  const userCalendarID = getEventUserCalendarID(event);
  return (
    googleColors.primaryEventsColors[eventColorID]?.color ===
      getCalendarColorHex(allCalendars[userCalendarID]) ||
    !eventColorID ||
    parseInt(eventColorID) === 0 // eventColorID "0" signifies no color
  );
}

export function getGoogleMapsAutoComplete() {
  // could have undefined object from window
  // https://vimcal.sentry.io/issues/5334155400/?project=2190664&query=typeerror&referrer=issue-stream&statsPeriod=90d&stream_index=3
  if (!window?.google?.maps?.places?.AutocompleteService) {
    return;
  }
  return new window.google.maps.places.AutocompleteService();
}

export function getGoogleMapsAutoCompleteAndDetail() {
  if (!window?.google?.maps?.places?.PlacesService) {
    return { autoComplete: null, detail: null };
  }
  const autoComplete = getGoogleMapsAutoComplete();

  // Need to call detail, otherwise we don't get street number
  // https://stackoverflow.com/questions/59807235/google-maps-places-autocomplete-not-returning-street-numbers
  const detail = new window.google.maps.places.PlacesService(
    document.createElement("div"),
  );
  return { autoComplete, detail };
}

export function getTimeZoneFromLocation({ lat, lng }) {
  const loc = `${lat}, ${lng}`; // Tokyo expressed as lat,lng tuple

  const targetDate = new Date(); // Current date/time of user computer
  const timestamp =
    targetDate.getTime() / SECOND_IN_MS + targetDate.getTimezoneOffset() * 60; // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC

  const apiCall =
    "https://maps.googleapis.com/maps/api/timezone/json?location=" +
    loc +
    "&timestamp=" +
    timestamp +
    "&key=" +
    GOOGLE_API_KEY;

  return fetch(apiCall);
}

export function getGeoCodeFromAddress(address) {
  if (!address) {
    return;
  }
  const encodedLocation = encodeURIComponent(address);
  const apiCall = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${GOOGLE_API_KEY}`;
  return fetch(apiCall);
}

// load code async
// https://developers.google.com/maps/documentation/javascript/overview#Loading_the_Maps_API
export const GOOGLE_PLACES_SCRIPT_SRC =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAtHmHWIzVLD3sKO_hh-2zYP6xOcjGpBWE&libraries=places&loading=async";
export const GOOGLE_PLACES_ID = "googlePlaces";

// https://developers.google.com/maps/documentation/geocoding/requests-geocoding#StatusCodes
export const GOOGLE_GEOCODING_RESPONSE_STATUS = {
  OK: "OK",
  ZERO_RESULTS: "ZERO_RESULTS",
  OVER_DAILY_LIMIT: "OVER_DAILY_LIMIT",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  INVALID_REQUEST: "INVALID_REQUEST",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

// https://developers.google.com/maps/documentation/timezone/requests-timezone#TimeZoneStatus
export const GOOGLE_TIME_ZONE_RESPONSE_STATUS = {
  OK: "OK",
  INVALID_REQUEST: "INVALID_REQUEST",
  OVER_DAILY_LIMIT: "OVER_DAILY_LIMIT",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  ZERO_RESULTS: "ZERO_RESULTS",
};
