type Falsey = false | "" | 0 | null | undefined | void

export function isTypeNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isTypeString(value: unknown): value is string {
  return typeof value === "string";
}

/**
 * This differs from the isEmptyArray function in arrayFunctions in that
 * this only returns true if the value is an array AND it is empty.
 *
 * Not exporting this for now to avoid confusion with the variant from
 * arrayFunctions. This is only used in this file for now anyway.
 */
function isEmptyArray(value: unknown): value is [] {
  return Array.isArray(value) && value.length === 0;
}

export function isEmptyArrayOrFalsey(value: unknown): value is [] | Falsey {
  return !value || isEmptyArray(value);
}

/**
 * Use caution with this type guard. This does not strictly check that the value
 * is an object of key/value pairs. Other data types also have a type of `object`
 * and will return `true` here. This includes:
 * - `null`
 * - Arrays
 * - `Date` instances
 * - `RegExp` instances
 *
 * Use `isDictionary` if you want to check if the value is a standard key-value object.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function isTypeObject(value: unknown): value is object | null {
  return typeof value === "object";
}

type Dictionary = Record<string | number | symbol, unknown>;

/**
 * Check that the value is a dictionary of key-value pairs. This excludes
 * types that are considered objects in JS, such as `null`, `Date`, and `RegExp`.
 */
export function isDictionary(value: unknown[]): value is never;
export function isDictionary(value: boolean): value is never;
export function isDictionary(value: null): value is never;
export function isDictionary(value: number): value is never;
export function isDictionary(value: string): value is never;
export function isDictionary(value: undefined): value is never;
export function isDictionary(value: Date): value is never;
export function isDictionary(value: RegExp): value is never;
export function isDictionary(value: Dictionary): value is Dictionary;
export function isDictionary(value: unknown): value is Dictionary {
  if (!value) {
    return false;
  }

  if (Array.isArray(value)) {
    return false;
  }

  if (typeof value !== "object") {
    return false;
  }

  if (value instanceof RegExp) {
    return false;
  }

  if (value instanceof Date) {
    return false;
  }

  return true;
}

function isEmptyObject(value: unknown): value is Record<string, never> {
  return (
    isTypeObject(value)
    && !!value
    && !Array.isArray(value)
    && Object.keys(value).length === 0
    && !(value instanceof RegExp)
  );
}

export function isEmptyObjectOrFalsey(value: unknown): value is Record<string, never> | Falsey {
  return !value || isEmptyObject(value);
}

export function isUndefined(value: unknown): value is undefined {
  return typeof value === "undefined";
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isNullOrUndefined(value: unknown): value is null | undefined {
  return isNull(value) || isUndefined(value);
}
