import { ENVIRONMENTS } from "../lib/vimcalVariables";

export function isLocal() {
  if (!process.env.REACT_APP_SERVER_BASE_URL) {
    return true;
  }

  return process.env.REACT_APP_SERVER_BASE_URL === ENVIRONMENTS.LOCAL;
}

export function isStaging() {
  return process.env.REACT_APP_SERVER_BASE_URL === ENVIRONMENTS.STAGING;
}

export function devErrorPrint(error: unknown, label: unknown) {
  if (isLocal()) {
    console.error("dev error: ", label, error);
  }
}

export function devPrint(...args: unknown[]) {
  if (isLocal()) {
    console.log(...args);
  }
}
